import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.scss';
import { AlbumPage } from './components/AlbumPage';
import { AlbumsPage } from './components/AlbumsPage';
import { FilePage } from './components/FilePage';
import { FolderPage } from './components/FolderPage';
import { ForgotPasswordPage } from './components/ForgotPasswordPage';
import { LandingPage } from './components/LandingPage';
import { LogInPage } from './components/LogInPage';
import { ResetPasswordPage } from './components/ResetPasswordPage';
import { SignOutPage } from './components/SignOutPage';
import { SignUpPage } from './components/SignUpPage';
import * as routes from './constants/routes';

const App: React.FC = () => {
  return (
    <div className="psv-app">
      <Router>
        <Switch>
          <Route exact={true} path="/">
            <LandingPage />
          </Route>
          <Route exact={true} path={routes.SignUp}>
            <SignUpPage />
          </Route>
          <Route exact={true} path={routes.Login}>
            <LogInPage />
          </Route>
          <Route exact={true} path={routes.SignOut}>
            <SignOutPage />
          </Route>
          <Route exact={true} path={routes.ForgotPassword}>
            <ForgotPasswordPage />
          </Route>
          <Route exact={true} path={routes.ResetPassword}>
            <ResetPasswordPage />
          </Route>
          <Route exact={true} path={routes.Folder}>
            <FolderPage />
          </Route>
          <Route exact={true} path={routes.FolderFile}>
            <FilePage />
          </Route>
          <Route exact={true} path={routes.Albums}>
            <AlbumsPage />
          </Route>
          <Route exact={true} path={routes.Album}>
            <AlbumPage />
          </Route>
          <Route exact={true} path={routes.AlbumFile}>
            <FilePage />
          </Route>
          <Route>
            <div>Not found!</div>
          </Route>
        </Switch>
      </Router>
      <ToastContainer position="bottom-right" theme="dark" />
    </div>
  );
};

export default App;
