import * as React from 'react';
import {
  faChevronRight,
  faCloudUploadAlt,
  faDownload,
  faEdit,
  faFileDownload,
  faFolder,
  faFolderPlus,
  faTruckMoving,
  faTrash
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { IFileBlob } from '@westlakelabs/http-client';
import FileSaver from 'file-saver';
import {
  contextMenu,
  Item,
  Menu,
  Separator,
  Submenu,
  TriggerEvent
} from 'react-contexify';
import { toast } from 'react-toastify';
import { IRecentFolder, usePromises } from '../common';
import { ExportDAL, FileDAL, FolderDAL } from '../DAL';
import { ConfirmationModal } from './ConfirmationModal';
import { CopyFileModal } from './CopyFileModal';
import { IFile, IFolder } from '@picstrata/client';
import { ErrorModal } from './ErrorModal';
import { FolderModal } from './FolderModal';
import './ContextMenus.scss';

interface IMenuIconProps {
  icon?: IconProp;
}

const MenuIcon = (props: IMenuIconProps) => {
  const { icon } = props;
  return <span>{icon && <FontAwesomeIcon icon={icon} />}</span>;
};

interface IGalleryContextMenuProps {
  folderId: string;
  uploadFilesButton: HTMLDivElement | null;
  onSelectAddFolder: () => void;
}

export const GalleryContextMenu = (props: IGalleryContextMenuProps) => {
  const { uploadFilesButton, onSelectAddFolder } = props;

  const handleClickAddFolder = React.useCallback(() => {
    onSelectAddFolder();
  }, [onSelectAddFolder]);

  const handleClickUploadFiles = React.useCallback(() => {
    if (uploadFilesButton) {
      uploadFilesButton.click();
    }
  }, [uploadFilesButton]);

  return (
    <div>
      <Menu id={`galleryContextMenu`}>
        <Item onClick={handleClickAddFolder}>
          <MenuIcon icon={faFolderPlus} />
          Add folder...
        </Item>
        <Item onClick={handleClickUploadFiles}>
          <MenuIcon icon={faCloudUploadAlt} />
          Upload files...
        </Item>
      </Menu>
    </div>
  );
};

export const useGalleryContextMenu = () => {
  return React.useCallback((e: TriggerEvent) => {
    contextMenu.show({
      id: `galleryContextMenu`,
      event: e
    });
  }, []);
};

interface IFolderContextMenuProps {
  canDeleteFolder: (folderId: string) => boolean;
  onUpdateFolder: (folder: IFolder) => void;
  onDeleteFolder: (folderId: string) => void;
  onDownloadFolder: (jobId: string) => void;
}

enum FolderMenuModal {
  RenameFolder = 1,
  ConfirmDelete = 2,
  Error = 3
}

export const FolderContextMenu = (props: IFolderContextMenuProps) => {
  const { canDeleteFolder, onDeleteFolder, onUpdateFolder, onDownloadFolder } =
    props;
  const [folderId, setFolderId] = React.useState<string>();
  const [folderName, setFolderName] = React.useState<string>();
  const [showModal, setShowModal] = React.useState<FolderMenuModal>();
  const [error, setError] = React.useState<string>();

  const promises = usePromises();

  const handleClickDownload = React.useCallback(
    args => {
      const folderId = args.triggerEvent.target.id;
      promises.add(
        'getFolderFiles',
        FolderDAL.getFolderFiles(folderId)
          .then(files => {
            return ExportDAL.addExportJob(files.map(f => f.fileId));
          })
          .then(exportJob => {
            onDownloadFolder(exportJob.jobId);
            return null;
          })
          .catch(() => {
            setError(
              'An error occured while exporting the files in your folder.'
            );
            setShowModal(FolderMenuModal.Error);
          })
      );
    },
    [promises, onDownloadFolder]
  );

  const handleClickRename = React.useCallback(args => {
    const folderDiv = args.triggerEvent.target as HTMLDivElement;
    setFolderId(folderDiv.id);
    setFolderName(folderDiv.children[0].textContent!);
    setShowModal(FolderMenuModal.RenameFolder);
  }, []);

  const handleClickDelete = React.useCallback(args => {
    setFolderId(args.triggerEvent.target.id);
    setShowModal(FolderMenuModal.ConfirmDelete);
  }, []);

  const handleCloseConfirmDelete = React.useCallback(
    (confirmed?: boolean) => {
      if (confirmed) {
        promises.add(
          'deleteFolder',
          FolderDAL.deleteFolder(folderId!)
            .then(() => {
              onDeleteFolder(folderId!);
              return null;
            })
            .catch(() => {
              setError('An unexpected error occurred deleting the folder.');
              setShowModal(FolderMenuModal.Error);
            })
        );
      }
      setFolderId(undefined);
      setShowModal(undefined);
    },
    [promises, folderId, onDeleteFolder]
  );

  const handleClickMoveTo = React.useCallback(() => {
    alert('Sorry, moving folders is not yet implemented.');
  }, []);

  const handleCloseFolderModal = React.useCallback(
    (folder?: IFolder) => {
      if (folder) {
        onUpdateFolder(folder);
      }
      setShowModal(undefined);
      setFolderId(undefined);
      setFolderName(undefined);
    },
    [onUpdateFolder]
  );

  const handleCloseErrorModal = React.useCallback(() => {
    setError(undefined);
    setShowModal(undefined);
  }, []);

  const isDeleteDisabled = React.useCallback(
    args => {
      if (canDeleteFolder) {
        return !canDeleteFolder(args.triggerEvent.target.id);
      }
      return true;
    },
    [canDeleteFolder]
  );

  return (
    <div>
      <Menu id={`folderContextMenu`}>
        <Item onClick={handleClickDownload}>
          <MenuIcon icon={faDownload} />
          Download
        </Item>
        <Item onClick={handleClickRename}>
          <MenuIcon icon={faEdit} />
          Rename
        </Item>
        <Item onClick={handleClickDelete} disabled={isDeleteDisabled}>
          <MenuIcon icon={faTrash} />
          Delete
        </Item>
        <Item onClick={handleClickMoveTo} disabled={isDeleteDisabled}>
          <MenuIcon icon={faTruckMoving} />
          Move To
        </Item>
      </Menu>

      {showModal === FolderMenuModal.RenameFolder && folderId && folderName && (
        <FolderModal
          onClose={handleCloseFolderModal}
          folderId={folderId}
          defaultValue={folderName}
        />
      )}

      {showModal === FolderMenuModal.ConfirmDelete && folderId && (
        <ConfirmationModal
          title="Delete Folder"
          message="Are you sure?"
          confirmCommand="Delete"
          isDestructive={true}
          onClose={handleCloseConfirmDelete}
        />
      )}

      {showModal === FolderMenuModal.Error && error && (
        <ErrorModal
          title="Error"
          message={error}
          onClose={handleCloseErrorModal}
        />
      )}
    </div>
  );
};

export const useFolderContextMenu = () => {
  return React.useCallback((e: TriggerEvent) => {
    contextMenu.show({
      id: `folderContextMenu`,
      event: e
    });
  }, []);
};

interface IFileContextMenuProps {
  folderId?: string;
  canDeleteFile?: (folderId: string) => boolean;
  onDeleteFile?: (fileId: string) => void;
  onSelectFolder?: (folder: IFolder) => void;
  recentFolders?: IRecentFolder[];
}

enum FileMenuModal {
  CopyFile = 1,
  MoveFile = 2,
  ConfirmDelete = 3,
  Error = 4
}

export const FileContextMenu = (props: IFileContextMenuProps) => {
  const { folderId, canDeleteFile, onDeleteFile, onSelectFolder } = props;
  const [showModal, setShowModal] = React.useState<FileMenuModal>();
  const [fileId, setFileId] = React.useState<string>();
  const [error, setError] = React.useState<string>();
  const promises = usePromises();

  // When showing recent folders for Copy or Move we don't want to
  // include the current folder in that list.
  const recentFolders = React.useMemo(() => {
    return props.recentFolders?.filter(f => f.folderId !== folderId);
  }, [props, folderId]);

  const handleClickDownload = React.useCallback(
    args => {
      const fileId = args.triggerEvent.target.id;
      promises.add(
        'downloadFile',
        FileDAL.downloadFile(fileId)
          .then((fileBlob: IFileBlob) => {
            fileBlob.blob.then((blob: Blob) => {
              FileSaver.saveAs(blob, fileBlob.filename);
            });
            return fileBlob;
          })
          .catch(() => {
            setError('An unexpected error occured downloading the file.');
            setShowModal(FileMenuModal.Error);
          })
      );
    },
    [promises]
  );

  const handleClickDelete = React.useCallback(args => {
    setFileId(args.triggerEvent.target.id);
    setShowModal(FileMenuModal.ConfirmDelete);
  }, []);

  const handleCloseConfirmDelete = React.useCallback(
    (confirm?: boolean) => {
      if (confirm && fileId) {
        promises.add(
          'deleteFile',
          FileDAL.deleteFile(fileId)
            .then((file: IFile) => {
              if (onDeleteFile) {
                onDeleteFile(file.fileId);
              }
              return null;
            })
            .catch(() => {
              setError('An unexpected error occured deleting the file.');
              setShowModal(FileMenuModal.Error);
            })
        );
      }
      setShowModal(undefined);
    },
    [promises, fileId, onDeleteFile]
  );

  const handleClickCopyToFolder = React.useCallback(args => {
    setFileId(args.triggerEvent.target.id);
    setShowModal(FileMenuModal.CopyFile);
  }, []);

  const handleClickkMoveToFolder = React.useCallback(args => {
    setFileId(args.triggerEvent.target.id);
    setShowModal(FileMenuModal.MoveFile);
  }, []);

  const handleClickCopyMoveToRecentFolder = React.useCallback(
    args => {
      const fileId = args.triggerEvent.target.id;
      const { isMove, folderId } = args.data;
      promises.add(
        'copy',
        FileDAL.copyFile(fileId, folderId!)
          .then(() => {
            if (isMove) {
              return FileDAL.deleteFile(fileId);
            } else {
              return Promise.resolve(null);
            }
          })
          .then(() => {
            toast(isMove ? 'File moved!' : 'File copied!', {
              type: 'success',
              hideProgressBar: true
            });
          })
          .catch(() => {
            setError('An unexpected error occurred.');
            setShowModal(FileMenuModal.Error);
          })
      );
    },
    [promises]
  );

  const handleCloseCopyFileModal = React.useCallback(
    (folder?: IFolder) => {
      if (folder) {
        if (showModal === FileMenuModal.MoveFile && fileId && onDeleteFile) {
          onDeleteFile(fileId);
        }
        if (onSelectFolder) {
          onSelectFolder(folder);
        }
      }
      setShowModal(undefined);
      setFileId(undefined);
    },
    [showModal, onDeleteFile, onSelectFolder, fileId]
  );

  const handleCloseErrorModal = React.useCallback(() => {
    setError(undefined);
    setShowModal(undefined);
  }, []);

  const isDeleteDisabled = React.useCallback(
    args => {
      if (canDeleteFile) {
        return !canDeleteFile(args.triggerEvent.target.id);
      }
      return true;
    },
    [canDeleteFile]
  );

  const hasRecentFolders =
    recentFolders !== undefined && recentFolders.length > 0;

  return (
    <div>
      <Menu id={`fileContextMenu`}>
        <Item onClick={handleClickDownload}>
          <MenuIcon icon={faFileDownload} />
          Download
        </Item>
        {onDeleteFile && (
          <Item onClick={handleClickDelete} disabled={isDeleteDisabled}>
            <MenuIcon icon={faTrash} />
            Delete
          </Item>
        )}
        <Submenu
          label={
            <>
              <MenuIcon />
              Copy to
            </>
          }
          arrow={<FontAwesomeIcon icon={faChevronRight} />}
        >
          <Item onClick={handleClickCopyToFolder}>
            <MenuIcon icon={faFolder} />
            Folder...
          </Item>
          {hasRecentFolders && <Separator />}
          {hasRecentFolders &&
            recentFolders!.map(f => (
              <Item
                key={f.folderId}
                data={{ ...f, isMove: false }}
                onClick={handleClickCopyMoveToRecentFolder}
              >
                {f.name}
              </Item>
            ))}
        </Submenu>
        {onDeleteFile && (
          <Submenu
            label={
              <>
                <MenuIcon />
                Move to
              </>
            }
            arrow={<FontAwesomeIcon icon={faChevronRight} />}
          >
            <Item
              onClick={handleClickkMoveToFolder}
              disabled={isDeleteDisabled}
            >
              <MenuIcon icon={faFolder} />
              Folder...
            </Item>
            {hasRecentFolders && <Separator />}
            {hasRecentFolders &&
              recentFolders!.map(f => (
                <Item
                  key={f.folderId}
                  data={{ ...f, isMove: true }}
                  onClick={handleClickCopyMoveToRecentFolder}
                >
                  {f.name}
                </Item>
              ))}
          </Submenu>
        )}
      </Menu>

      {showModal === FileMenuModal.CopyFile && fileId && (
        <CopyFileModal
          fileId={fileId}
          excludeFolders={folderId ? [folderId] : []}
          onClose={handleCloseCopyFileModal}
        />
      )}

      {showModal === FileMenuModal.MoveFile && fileId && (
        <CopyFileModal
          fileId={fileId}
          deleteSource={true}
          excludeFolders={folderId ? [folderId] : []}
          onClose={handleCloseCopyFileModal}
        />
      )}

      {showModal === FileMenuModal.ConfirmDelete && fileId && (
        <ConfirmationModal
          title="Delete File"
          message="Are you sure?"
          confirmCommand="Delete"
          isDestructive={true}
          onClose={handleCloseConfirmDelete}
        />
      )}

      {showModal === FileMenuModal.Error && error && (
        <ErrorModal
          title="Error"
          message={error}
          onClose={handleCloseErrorModal}
        />
      )}
    </div>
  );
};

export const useFileContextMenu = () => {
  return React.useCallback((e: TriggerEvent) => {
    contextMenu.show({
      id: `fileContextMenu`,
      event: e
    });
  }, []);
};

interface IAlbumGalleryContextMenuProps {
  onAddAlbum: () => void;
}

export const AlbumGalleryContextMenu = (
  props: IAlbumGalleryContextMenuProps
) => {
  const { onAddAlbum } = props;

  const handleAddAlbumClick = React.useCallback(() => {
    onAddAlbum();
  }, [onAddAlbum]);

  return (
    <div>
      <Menu id={`albumGalleryContextMenu`}>
        <Item onClick={handleAddAlbumClick}>Add Album</Item>
      </Menu>
    </div>
  );
};

export const useAlbumGalleryContextMenu = () => {
  return React.useCallback((e: TriggerEvent) => {
    contextMenu.show({
      id: `albumGalleryContextMenu`,
      event: e
    });
  }, []);
};
