import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IAlbum, IBreadcrumb, IFolder } from '@picstrata/client';
import classnames from 'classnames';
import React from 'react';
import { useHistory } from 'react-router';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from 'reactstrap';
import { usePromises } from '../common/PromiseCollection';
import { FolderDAL } from '../DAL/FolderDAL';
import './BreadcrumbBar.scss';

const MAX_PARENTS = 3;
const MORE_PARENTS_INDEX = 2;
const MORE_PARENTS_FOLDER_ID = 'unused';

export enum LibraryView {
  Folder,
  Album
}

interface IBreadcrumbBarProps {
  view: LibraryView;
  folder?: IFolder;
  album?: IAlbum;
}

export const BreadcrumbBar = (props: IBreadcrumbBarProps) => {
  const { view, folder, album } = props;
  const [breadcrumbs, setBreadcrumbs] = React.useState<React.ReactElement>(
    <React.Fragment />
  );
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const promises = usePromises();
  const history = useHistory();

  React.useEffect(() => {
    // Are we browsing all photos?
    if (view === LibraryView.Folder && folder) {
      let hiddenParents: IBreadcrumb[];
      promises.add(
        'getBreadcrumbs',
        FolderDAL.getFolderBreadcrumbs(folder.folderId).then(crumbs => {
          // The breadcrumb bar shows a max of three parents plus the current
          // folder.  If there are more parents then we remove some from the
          // middle and replace them with an ellipsis.
          if (crumbs.length > MAX_PARENTS) {
            hiddenParents = crumbs.splice(
              MORE_PARENTS_INDEX,
              crumbs.length - MAX_PARENTS,
              {
                libraryId: folder.libraryId,
                folderId: MORE_PARENTS_FOLDER_ID,
                name: '...'
              }
            );
          }

          setBreadcrumbs(
            <div>
              {crumbs.map((c, i) => {
                return hiddenParents && i === MORE_PARENTS_INDEX ? (
                  <React.Fragment key={i}>
                    <span className="psv-breadcrumb">{c.name}</span>
                    <span className="psv-breadcrumb-separator" />
                  </React.Fragment>
                ) : (
                  <React.Fragment key={i}>
                    <a
                      href={`/libraries/${folder.libraryId}/folders/${c.folderId}`}
                    >
                      <span className="psv-breadcrumb">{c.name}</span>
                    </a>
                    <span className="psv-breadcrumb-separator" />
                  </React.Fragment>
                );
              })}

              {folder.name}
            </div>
          );
          return crumbs;
        })
      );
    }

    // Are we browing albums?
    if (view === LibraryView.Album) {
      if (album) {
        setBreadcrumbs(
          <div>
            <a href={`/libraries/${album.libraryId}/albums`}>
              <span className="psv-breadcrumb">Albums</span>
            </a>
            <span className="psv-breadcrumb-separator" />
            {album.name}
          </div>
        );
      } else {
        setBreadcrumbs(<div>Albums</div>);
      }
    }
  }, [view, folder, album, promises]);

  const handleToggleDropdown = () => setDropdownOpen(prevState => !prevState);

  const handleAllPhotosClick = React.useCallback(() => {
    FolderDAL.getAllPicturesFolder().then(allPictures => {
      history.push(
        `/libraries/${allPictures.libraryId}/folders/${allPictures.folderId}`
      );
    });
  }, [history]);

  const handleAlbumsClick = React.useCallback(() => {
    history.push(
      `/libraries/${folder ? folder.libraryId : album!.libraryId}/albums`
    );
  }, [history, folder, album]);

  return (
    <div className="psv-breadcrumb-bar">
      <Dropdown isOpen={dropdownOpen} toggle={handleToggleDropdown}>
        <DropdownToggle
          tag="span"
          data-toggle="dropdown"
          aria-expanded={dropdownOpen}
        >
          <FontAwesomeIcon icon={faBars} />
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem
            className={classnames('psv-breadcrumb-menuitem', {
              'psv-breadcrumb-menuitem-selected': folder !== undefined
            })}
            onClick={handleAllPhotosClick}
          >
            All Photos
          </DropdownItem>
          <DropdownItem
            className={classnames('psv-breadcrumb-menuitem', {
              'psv-breadcrumb-menuitem-selected': album !== undefined
            })}
            onClick={handleAlbumsClick}
          >
            Albums
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>

      {breadcrumbs}
    </div>
  );
};
