import {
  ErrorMessage,
  Field,
  Form,
  Formik,
  FormikHelpers,
  FormikProps
} from 'formik';
import HttpStatus from 'http-status-codes';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Col, Container, Navbar, NavbarBrand, Row } from 'reactstrap';
import * as Yup from 'yup';
import { usePromises } from '../common/PromiseCollection';
import { Session } from '../common/Session';
import { FolderDAL } from '../DAL/FolderDAL';
import { UserDAL } from '../DAL/UserDAL';

interface ISignUpValues {
  email: string;
  fullName: string;
  password: string;
  confirmPassword: string;
  invitationCode: string;
}

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email address.')
    .required('Please enter your email address.'),
  fullName: Yup.string().required('Please enter your full name.'),
  password: Yup.string().required('Please provide a password.'),
  confirmPassword: Yup.string()
    .required('Please confirm your password.')
    .oneOf([Yup.ref('password')], 'Passwords do not match'),
  invitationCode: Yup.string().required('Please provide your invitation code.')
});

const defaultValues: ISignUpValues = {
  email: '',
  fullName: '',
  password: '',
  confirmPassword: '',
  invitationCode: ''
};

export const SignUpPage = () => {
  const history = useHistory();
  const promises = usePromises();
  const [saveError, setSaveError] = React.useState<string | undefined>(
    undefined
  );

  const handleSubmit = React.useCallback(
    async (values: ISignUpValues, helpers: FormikHelpers<ISignUpValues>) => {
      helpers.setSubmitting(true);
      promises.add(
        'signup',
        UserDAL.signUp(
          values.email,
          values.password,
          values.fullName,
          values.invitationCode
        )
          .then(_ => {
            helpers.setSubmitting(false);
            return FolderDAL.getAllPicturesFolder().then(folder => {
              history.push(
                `/libraries/${Session.getCurrentLibraryId()}/folders/${
                  folder.folderId
                }`
              );
            });
          })
          .catch((err: any) => {
            setSaveError(
              err.status && err.status === HttpStatus.BAD_REQUEST
                ? 'Invalid invitation code'
                : 'An unexpected error occurred.'
            );
            helpers.setSubmitting(false);
          })
      );
    },
    [promises, history]
  );

  return (
    <div>
      <Navbar dark color="dark">
        <NavbarBrand color="light">PICSILVER</NavbarBrand>
      </Navbar>
      <Formik
        initialValues={defaultValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        children={(props: FormikProps<ISignUpValues>) => {
          return (
            <Form>
              <Container className="psv-inline-form">
                <h5>SIGN UP</h5>
                <Row>
                  <Col className="form-group">
                    <label htmlFor="email">E-mail address: </label>
                    <Field
                      name="email"
                      placeholder="Enter your e-mail address"
                      className="form-control"
                      autoFocus={true}
                    />
                    <ErrorMessage
                      className="ps-error"
                      name="email"
                    ></ErrorMessage>
                  </Col>
                </Row>
                <Row>
                  <Col className="form-group">
                    <label htmlFor="fullName">Full Name:</label>
                    <Field
                      name="fullName"
                      placeholder="Full name"
                      className="form-control"
                    />
                    <ErrorMessage name="fullName"></ErrorMessage>
                  </Col>
                </Row>

                <Row>
                  <Col className="form-group">
                    <label htmlFor="password">Password:</label>
                    <Field
                      type="password"
                      name="password"
                      placeholder="Password to use when logging in"
                      className="form-control"
                    />
                    <ErrorMessage name="password"></ErrorMessage>
                  </Col>
                </Row>

                <Row>
                  <Col className="form-group">
                    <label htmlFor="confirmPassword">Confirm Password:</label>
                    <Field
                      type="password"
                      name="confirmPassword"
                      placeholder="Password to use when logging in"
                      className="form-control"
                    />
                    <ErrorMessage name="confirmPassword"></ErrorMessage>
                  </Col>
                </Row>

                <Row>
                  <Col className="form-group">
                    <label htmlFor="invitationCode">Invitation Code:</label>
                    <Field
                      name="invitationCode"
                      placeholder="Invitation code"
                      className="form-control"
                    />
                    <ErrorMessage name="invitationCode"></ErrorMessage>
                  </Col>
                </Row>
                {saveError && (
                  <Row>
                    <Col className="danger-text">{saveError}</Col>
                  </Row>
                )}

                <Row>
                  <Col>
                    <Button
                      className="psv-full-width-button"
                      color="primary"
                      type="submit"
                      disabled={
                        !props.dirty || !props.isValid || props.isSubmitting
                      }
                    >
                      Sign Up
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Form>
          );
        }}
      />
    </div>
  );
};
