import { ILibrary, ILibraryAdd } from '@picstrata/client';
import { HttpClient, HttpResult } from '@westlakelabs/http-client';
import Promise from 'bluebird';
import { Session } from '../common/Session';

export class LibraryDAL {
  public static addLibrary(name: string): Promise<ILibrary> {
    return HttpClient.post<ILibrary>(LibraryDAL.getRouteBase(), {
      name,
      timeZone: 'America/Los_Angeles'
    } as ILibraryAdd).then((result: HttpResult<ILibrary>) => {
      return result.data;
    });
  }

  public static getCurrentLibrary(): Promise<ILibrary> {
    return HttpClient.get<ILibrary>(
      `${LibraryDAL.getRouteBase()}/${Session.getCurrentLibraryId()}`
    ).then((result: HttpResult<ILibrary>) => {
      return result.data;
    });
  }

  private static getRouteBase() {
    return `/api/libraries`;
  }
}
