import Promise from 'bluebird';
import { FolderDAL } from '../DAL';

enum PreferenceKey {
  RecentFolders = 'psv_recent_folders'
}

export interface IRecentFolder {
  folderId: string;
  name: string;
}

export class Preferences {
  public static getRecentFolders(): Promise<IRecentFolder[]> {
    const recent = localStorage.getItem(PreferenceKey.RecentFolders);
    if (!recent) {
      return Promise.resolve([]);
    }

    const folderIds: string[] = JSON.parse(recent);
    return Promise.all(
      folderIds.map(folderId =>
        FolderDAL.getFolder(folderId).then(folder => {
          return { folderId: folder.folderId, name: folder.name };
        })
      )
    );
  }

  public static setRecentFolders(
    recentFolders: IRecentFolder[]
  ): IRecentFolder[] {
    const slice = recentFolders.slice(0, 5);
    localStorage.setItem(
      PreferenceKey.RecentFolders,
      JSON.stringify(slice.map(recent => recent.folderId))
    );
    return slice;
  }
}
