import {
  ErrorMessage,
  Field,
  Form,
  Formik,
  FormikHelpers,
  FormikProps
} from 'formik';
import React from 'react';
import { Button, Col, Container, Navbar, NavbarBrand, Row } from 'reactstrap';
import * as Yup from 'yup';
import { usePromises } from '../common/PromiseCollection';
import * as routes from '../constants/routes';
import { UserDAL } from '../DAL/UserDAL';

interface IFormValues {
  email: string;
}

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email address.')
    .required('Please enter your email address.')
});

const defaultValues: IFormValues = {
  email: ''
};

export const ForgotPasswordPage = () => {
  const promises = usePromises();
  const [emailSent, setEmailSent] = React.useState<boolean>(false);
  const [saveError, setSaveError] = React.useState<string | undefined>(
    undefined
  );

  const handleSubmit = React.useCallback(
    async (values: IFormValues, helpers: FormikHelpers<IFormValues>) => {
      helpers.setSubmitting(true);
      promises.add(
        'login',
        UserDAL.sendPasswordReset(values.email)
          .then(success => {
            setEmailSent(true);
          })
          .catch((err: any) => {
            setSaveError(
              'An unexpected error occurred.  Please refresh the page and try your operation again.'
            );
          })
          .finally(() => {
            helpers.setSubmitting(false);
          })
      );
    },
    [promises]
  );

  if (emailSent) {
    return (
      <div>
        <Navbar dark color="dark">
          <NavbarBrand color="light">PICSILVER</NavbarBrand>
        </Navbar>
        <div>
          <Container className="psv-inline-form">
            <h5>Check your inbox</h5>
            <p>
              We sent you an email with details on recovering your password.
            </p>
            <a
              className="btn btn-primary psv-full-width-button"
              href={routes.Login}
            >
              Back to Log In
            </a>
          </Container>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Navbar dark color="dark">
        <NavbarBrand color="light">PICSILVER</NavbarBrand>
      </Navbar>
      <Formik
        initialValues={defaultValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        children={(props: FormikProps<IFormValues>) => {
          return (
            <Form>
              <Container className="psv-inline-form">
                <h5>RESET PASSWORD</h5>
                <Row>
                  <Col className="form-group">
                    <label htmlFor="email">E-mail address: </label>
                    <Field
                      name="email"
                      placeholder="Enter your e-mail address"
                      className="form-control"
                      autoFocus={true}
                    />
                    <ErrorMessage
                      className="ps-error"
                      name="email"
                    ></ErrorMessage>
                  </Col>
                </Row>
                {saveError && (
                  <Row>
                    <Col className="danger-text">{saveError}</Col>
                  </Row>
                )}

                <Row>
                  <Col>
                    <Button
                      className="psv-full-width-button"
                      color="primary"
                      type="submit"
                      disabled={
                        !props.dirty || !props.isValid || props.isSubmitting
                      }
                    >
                      Send Reset E-mail
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Form>
          );
        }}
      />
    </div>
  );
};
