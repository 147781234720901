import React from 'react';
import { useHistory } from 'react-router';
import { Button, Navbar, NavbarBrand } from 'reactstrap';
import './LandingPage.scss';

export const LandingPage = () => {
  const history = useHistory();

  const handleLogInClick = React.useCallback(() => {
    history.push('/login');
  }, [history]);

  const handleSignUpClick = React.useCallback(() => {
    history.push('/signup');
  }, [history]);

  return (
    <div>
      <Navbar dark color="dark">
        <NavbarBrand color="light">PICSILVER</NavbarBrand>
      </Navbar>
      <div className="psv-landing-container">
        <div>
          <Button color="primary" onClick={handleLogInClick}>
            Log In
          </Button>
        </div>
        <div>
          <Button color="secondary" onClick={handleSignUpClick}>
            Sign Up
          </Button>
        </div>
      </div>
    </div>
  );
};
