import { IUserCode } from '@picsilver/common';
import {
  ErrorMessage,
  Field,
  Form,
  Formik,
  FormikHelpers,
  FormikProps
} from 'formik';
import React from 'react';
import { Button, Col, Container, Navbar, NavbarBrand, Row } from 'reactstrap';
import * as Yup from 'yup';
import { usePromises } from '../common/PromiseCollection';
import * as routes from '../constants/routes';
import { UserCodeDAL } from '../DAL/UserCodeDAL';
import queryString from 'query-string';

interface IFormValues {
  password: string;
  confirmPassword: string;
}

const validationSchema = Yup.object().shape({
  password: Yup.string().required('Please provide a password.'),
  confirmPassword: Yup.string()
    .required('Please confirm your password.')
    .oneOf([Yup.ref('password')], 'Passwords do not match')
});

const defaultValues: IFormValues = {
  password: '',
  confirmPassword: ''
};

export const ResetPasswordPage = () => {
  const promises = usePromises();
  const [code, setCode] = React.useState<string>();
  const [userCode, setUserCode] = React.useState<IUserCode>();
  const [codeError, setCodeError] = React.useState<string>();
  const [passwordReset, setPasswordReset] = React.useState<boolean>(false);
  const [saveError, setSaveError] = React.useState<string>();

  React.useEffect(() => {
    const params = queryString.parse(window.location.search);
    setCode(params.code as string);
    promises.add(
      'getEmailToken',
      UserCodeDAL.getUserCode(params.code as string)
        .then(userCode => {
          setUserCode(userCode);
        })
        .catch(err => {
          setCodeError('The link is no longer valid.');
        })
    );
  }, [promises]);

  const handleSubmit = React.useCallback(
    async (values: IFormValues, helpers: FormikHelpers<IFormValues>) => {
      helpers.setSubmitting(true);
      promises.add(
        'setPassword',
        UserCodeDAL.invokeUserCode(code!, { password: values.password })
          .then(success => {
            setPasswordReset(true);
          })
          .catch((err: any) => {
            setSaveError(
              'An unexpected error occurred.  Please refresh the page and try your operation again.'
            );
          })
          .finally(() => {
            helpers.setSubmitting(false);
          })
      );
    },
    [promises, code]
  );

  if (codeError) {
    return (
      <div>
        <Navbar dark color="dark">
          <NavbarBrand color="light">PICSILVER</NavbarBrand>
        </Navbar>
        <div>
          <Container className="psv-inline-form">
            <h5>Invalid reset password link!</h5>
            <p>
              {codeError} Click the button below to go back to the log in page.
            </p>
            <a
              className="btn btn-primary psv-full-width-button"
              href={routes.Login}
            >
              Log In
            </a>
          </Container>
        </div>
      </div>
    );
  }

  if (passwordReset) {
    return (
      <div>
        <Navbar dark color="dark">
          <NavbarBrand color="light">PICSILVER</NavbarBrand>
        </Navbar>
        <div>
          <Container className="psv-inline-form">
            <h5>Your password has been changed!</h5>
            <p>Please click the button below to log in.</p>
            <a
              className="btn btn-primary psv-full-width-button"
              href={routes.Login}
            >
              Log In
            </a>
          </Container>
        </div>
      </div>
    );
  }

  if (!userCode) {
    return null;
  }

  return (
    <div>
      <Navbar dark color="dark">
        <NavbarBrand color="light">PICSILVER</NavbarBrand>
      </Navbar>
      <Formik
        initialValues={defaultValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        children={(props: FormikProps<IFormValues>) => {
          return (
            <Form>
              <Container className="psv-inline-form">
                <h5>CHANGE PASSWORD</h5>

                <Row>
                  <Col className="form-group">
                    <label htmlFor="password">Password: </label>
                    <Field
                      type="password"
                      name="password"
                      placeholder="Password to use when logging in"
                      className="form-control"
                      autoFocus={true}
                    />
                    <ErrorMessage
                      className="ps-error"
                      name="password"
                    ></ErrorMessage>
                  </Col>
                </Row>

                <Row>
                  <Col className="form-group">
                    <label htmlFor="confirmPassword">Confirm Password:</label>
                    <Field
                      type="password"
                      name="confirmPassword"
                      placeholder="Password to use when logging in"
                      className="form-control"
                    />
                    <ErrorMessage name="confirmPassword"></ErrorMessage>
                  </Col>
                </Row>

                {saveError && (
                  <Row>
                    <Col className="danger-text">{saveError}</Col>
                  </Row>
                )}

                <Row>
                  <Col>
                    <Button
                      className="psv-full-width-button"
                      color="primary"
                      type="submit"
                      disabled={
                        !props.dirty || !props.isValid || props.isSubmitting
                      }
                    >
                      Change Password
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Form>
          );
        }}
      />
    </div>
  );
};
