import Promise from 'bluebird';
import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { toast } from 'react-toastify';
import { usePromises } from '../common/PromiseCollection';
import { FileDAL } from '../DAL/FileDAL';
import { FolderTree } from './FolderTree';
import { IFolder } from '@picstrata/client';

interface ICopyFileModalProps {
  fileId: string;
  deleteSource?: boolean;
  excludeFolders?: string[];
  onClose: (folder?: IFolder) => void;
}

/**
 * Modal that allows the user to select a folder and then copy or
 * move a file to that modal.
 */
export const CopyFileModal = (props: ICopyFileModalProps) => {
  const { fileId, deleteSource, excludeFolders, onClose } = props;
  const promises = usePromises();
  const [folder, setFolder] = React.useState<IFolder>();
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>();

  const handleFolderSelect = React.useCallback((folder: IFolder) => {
    setFolder(folder);
  }, []);

  const handleSubmit = React.useCallback(() => {
    setIsSubmitting(true);
    const folderId = folder!.folderId;
    promises.add(
      'copy',
      FileDAL.copyFile(fileId, folderId!)
        .then(() => {
          if (deleteSource) {
            return FileDAL.deleteFile(fileId);
          } else {
            return Promise.resolve(null);
          }
        })
        .then(() => {
          toast(deleteSource ? 'File moved!' : 'File copied!', {
            type: 'success',
            hideProgressBar: true
          });
          onClose(folder);
        })
        .catch(() => {
          setError('An unexpected error occurred.');
        })
        .finally(() => {
          setIsSubmitting(false);
        })
    );
  }, [promises, fileId, folder, deleteSource, onClose]);

  const handleCancelClick = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      onClose();
    },
    [onClose]
  );

  return (
    <Modal isOpen={true} autoFocus={false} centered={true}>
      <ModalHeader toggle={handleCancelClick}>
        {deleteSource ? 'Move File to Folder' : 'Copy File To Folder'}
      </ModalHeader>
      <ModalBody>
        <FolderTree onSelect={handleFolderSelect} />
        {error}
      </ModalBody>
      <ModalFooter>
        <Button
          color="secondary"
          disabled={isSubmitting}
          onClick={handleCancelClick}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          color="primary"
          disabled={
            isSubmitting ||
            !folder?.folderId ||
            (excludeFolders &&
              folder &&
              excludeFolders.includes(folder.folderId))
          }
          onClick={handleSubmit}
        >
          {deleteSource ? 'Move' : 'Copy'}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
