import { IAlbum, IAlbumAdd, IFile } from '@picstrata/client';
import { HttpClient, HttpResult } from '@westlakelabs/http-client';
import Promise from 'bluebird';
import { Session } from '../common/Session';

export class AlbumDAL {
  public static addAlbum(name: string): Promise<IAlbum> {
    return HttpClient.post<IAlbum>(AlbumDAL.getRouteBase(), {
      name
    } as IAlbumAdd).then((result: HttpResult<IAlbum>) => {
      return result.data;
    });
  }

  public static getAlbums(): Promise<IAlbum[]> {
    return HttpClient.get<IAlbum[]>(`${AlbumDAL.getRouteBase()}`).then(
      (result: HttpResult<IAlbum[]>) => {
        return result.data;
      }
    );
  }

  public static getAlbum(albumId: string): Promise<IAlbum> {
    return HttpClient.get<IAlbum>(`${AlbumDAL.getRouteBase()}/${albumId}`).then(
      (result: HttpResult<IAlbum>) => {
        return result.data;
      }
    );
  }

  public static getAlbumFiles(albumId: string): Promise<IFile[]> {
    return HttpClient.get<IFile[]>(
      `${AlbumDAL.getRouteBase()}/${albumId}/files`
    ).then((result: HttpResult<IFile[]>) => {
      return result.data;
    });
  }

  private static getRouteBase() {
    return `/api/libraries/${Session.getCurrentLibraryId()}/albums`;
  }
}
