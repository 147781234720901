import { IAlbum, IFile, ThumbnailSize } from '@picstrata/client';
import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { usePromises } from '../common/PromiseCollection';
import { Session } from '../common/Session';
import * as routes from '../constants/routes';
import { AlbumDAL } from '../DAL/AlbumDAL';
import { useFileLoader } from '../hooks/FileLoader';
import folderImg from '../images/folder.svg';
import loadingImg from '../images/loading.gif';
import { AppNavbar } from './AppNavbar';
import { BreadcrumbBar, LibraryView } from './BreadcrumbBar';
import { FileContextMenu, useFileContextMenu } from './ContextMenus';
import './FolderPage.scss';
import { Gallery } from './Gallery';
import './Library.scss';

export const AlbumPage = () => {
  const history = useHistory();
  const match = useRouteMatch(routes.Album);
  const libraryId = Session.getCurrentLibraryId();
  const albumId = (match!.params as any).albumId;
  const [files, setFiles] = React.useState<IFile[]>();
  const [album, setAlbum] = React.useState<IAlbum>();
  const promises = usePromises();

  useFileLoader(AlbumDAL.getAlbumFiles, albumId, files, setFiles);
  const handleFileContextMenu = useFileContextMenu();

  /**
   * Fetches album information using the album ID in the URL.
   */
  React.useEffect(() => {
    promises.add(
      'getAlbum',
      AlbumDAL.getAlbum(albumId).then(a => {
        setAlbum(a);
      }),
      setAlbum
    );
  }, [albumId, promises]);

  const getFileUrl = React.useCallback((file: IFile) => {
    return `/api/libraries/${Session.getCurrentLibraryId()}/files/${
      file.fileId
    }/thumbnails/${ThumbnailSize.Medium}`;
  }, []);

  const handleViewFile = React.useCallback(
    (file: IFile) => {
      history.push(
        `/libraries/${libraryId}/albums/${albumId}/files/${file.fileId}`
      );
    },
    [libraryId, albumId, history]
  );

  return (
    <React.Fragment>
      <AppNavbar />

      <FileContextMenu />

      <div className="psv-library-header">
        <div className="psv-library-name">
          {album && <BreadcrumbBar view={LibraryView.Album} album={album} />}
        </div>
      </div>

      {files && (
        <Gallery
          className="psv-library-gallery"
          folders={[]}
          files={files}
          onFileContextMenu={handleFileContextMenu}
          getFileUrl={getFileUrl}
          onViewFile={handleViewFile}
          folderImg={folderImg}
          loadingImg={loadingImg}
        />
      )}
    </React.Fragment>
  );
};
