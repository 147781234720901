import { IExportJob, IExportJobAdd } from '@picstrata/client';
import { HttpClient, HttpResult, IFileBlob } from '@westlakelabs/http-client';
import Promise from 'bluebird';
import { Session } from '../common/Session';

export class ExportDAL {
  public static addExportJob(fileIds: string[]): Promise<IExportJob> {
    const libraryId = Session.getCurrentLibraryId();
    return HttpClient.post<IExportJob>(
      `/api/libraries/${libraryId}/exportjobs`,
      {
        fileIds
      } as IExportJobAdd
    ).then((result: HttpResult<IExportJob>) => {
      return result.data;
    });
  }

  public static getExportJob(jobId: string) {
    const libraryId = Session.getCurrentLibraryId();
    return HttpClient.get<IExportJob>(
      `/api/libraries/${libraryId}/exportjobs/${jobId}`
    ).then((result: HttpResult<IExportJob>) => {
      return result.data;
    });
  }

  public static downloadExport(jobId: string) {
    const libraryId = Session.getCurrentLibraryId();
    return HttpClient.downloadFile(
      `/api/libraries/${libraryId}/exports/${jobId}?attachment=true`
    ).then((result: HttpResult<IFileBlob>) => {
      return result.data;
    });
  }
}
