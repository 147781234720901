export const Landing = '/';
export const SignUp = '/signup';
export const Login = '/login';
export const SignOut = '/signout';
export const ForgotPassword = '/forgot-password';
export const ResetPassword = '/reset-password';
export const Library = '/libraries/:libraryId';
export const Folder = Library + '/folders/:folderId';
export const FolderFile = Folder + '/files/:fileId';
export const Albums = Library + '/albums';
export const Album = Library + '/albums/:albumId';
export const AlbumFile = Album + '/files/:fileId';
