import Promise from 'bluebird';

/**
 * Per Bluebird docs:
 *
 *  "Cancellation is always configured separately per bluebird instance."
 *
 * http://bluebirdjs.com/docs/api/promise.config.html
 */

// Before absolutely anything else, enable cancellation of promises.
Promise.config({
  warnings: false,
  longStackTraces: false,
  cancellation: true,
  monitoring: false
});
