import { IUserCode } from '@picsilver/common';
import { HttpClient, HttpResult } from '@westlakelabs/http-client';

export class UserCodeDAL {
  public static getUserCode(code: string) {
    return HttpClient.get<IUserCode>(
      `${UserCodeDAL.getRouteBase()}/${code}`
    ).then((result: HttpResult<IUserCode>) => {
      return result.data;
    });
  }

  public static invokeUserCode(code: string, payload: any) {
    return HttpClient.put<IUserCode>(
      `${UserCodeDAL.getRouteBase()}/${code}/invoke`,
      payload
    ).then((result: HttpResult<IUserCode>) => {
      return result.data;
    });
  }

  private static getRouteBase() {
    return `/api/usercodes`;
  }
}
