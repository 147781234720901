import * as React from 'react';
import { ILibrary } from '@picstrata/client';
import { useHistory } from 'react-router-dom';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarBrand,
  UncontrolledDropdown
} from 'reactstrap';
import { usePromises } from '../common/PromiseCollection';
import { Session } from '../common/Session';
import { AddLibraryModal } from '../components/AddLibraryModal';
import { FolderDAL } from '../DAL/FolderDAL';
import './AppNavbar.scss';
import { UserDAL } from '../DAL/UserDAL';

export const AppNavbar = () => {
  const [libraries, setLibraries] = React.useState<ILibrary[]>(
    Session.getLibraries()
  );
  const [showAddLibraryModal, setShowAddLibraryModal] =
    React.useState<boolean>(false);
  const history = useHistory();
  const promises = usePromises();

  const openAllPhotos = React.useCallback(
    (libraryId: string) => {
      promises.add(
        'getFolder',
        FolderDAL.getAllPicturesFolder().then(folder => {
          history.push(`/libraries/${libraryId}/folders/${folder.folderId}`);
        }),
        setLibraries
      );
    },
    [history, promises]
  );

  const handleLibraryClick = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      const libraryId = e.currentTarget.dataset.libraryId!;
      const library = libraries.find(l => l.libraryId === libraryId)!;
      Session.setCurrentLibrary(library);
      openAllPhotos(libraryId);

      promises.add(
        'setMostRecentLibraryId',
        UserDAL.setMostRecentLibrary(libraryId).catch(err => {
          /* Do nothing if the update fails--it isn't the end of the world. */
        })
      );
    },
    [libraries, openAllPhotos, promises]
  );

  const handleNewLibraryClick = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      setShowAddLibraryModal(true);
    },
    []
  );

  const handleNewLibraryModalClose = (library?: ILibrary) => {
    setShowAddLibraryModal(false);

    if (library) {
      Session.addLibrary(library);
      Session.setCurrentLibrary(library);
      setLibraries(Session.getLibraries());
      openAllPhotos(library.libraryId);
    }
  };

  const handleSignOutClick = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      return history.push('/signout');
    },
    [history]
  );

  return (
    <React.Fragment>
      <Navbar dark color="dark">
        <NavbarBrand color="light" className="mr-auto">
          {Session.getCurrentLibraryName()}
        </NavbarBrand>
        <Nav navbar>
          <UncontrolledDropdown nav>
            <DropdownToggle nav caret>
              <div className="user-icon">{Session.getUserInitials()}</div>
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem>
                <b>{Session.getUserEmail()}</b>
              </DropdownItem>
              <DropdownItem divider />
              {libraries.map(l => (
                <DropdownItem
                  key={l.libraryId}
                  className={
                    l.libraryId === Session.getCurrentLibraryId()
                      ? 'psv-menu-item-checked'
                      : ''
                  }
                >
                  <div
                    onClick={handleLibraryClick}
                    data-library-id={l.libraryId}
                  >
                    {l.name}
                  </div>
                </DropdownItem>
              ))}
              <DropdownItem>
                <div onClick={handleNewLibraryClick}>New Library...</div>
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem>
                <div onClick={handleSignOutClick}>Sign Out</div>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      </Navbar>

      {showAddLibraryModal && (
        <AddLibraryModal
          onClose={handleNewLibraryModalClose}
          defaultValue="My New Library"
        />
      )}
    </React.Fragment>
  );
};
