import React from 'react';
import { useHistory } from 'react-router-dom';
import { usePromises } from '../common/PromiseCollection';
import { UserDAL } from '../DAL/UserDAL';

export const SignOutPage = () => {
  const history = useHistory();
  const promises = usePromises();

  React.useEffect(() => {
    promises.add(
      'signout',
      UserDAL.signOut().catch(err => {
        /* Do nothing */
      })
    );
    history.replace('/');
  });

  return <div>Signing out...</div>;
};
