import {
  ILibraryUser,
  ISignOutResponse,
  ISignUpPost,
  ISimpleResponse,
  IUser,
  IUserPatch
} from '@picsilver/common';
import { HttpClient, HttpResult } from '@westlakelabs/http-client';
import Promise from 'bluebird';
import { Session } from '../common/Session';

export class UserDAL {
  public static signUp(
    email: string,
    password: string,
    fullName: string,
    invitationCode: string
  ): Promise<IUser> {
    return HttpClient.postFormUrlEncoded<ILibraryUser>(
      `${UserDAL.getRouteBase()}/signup`,
      {
        email,
        password,
        fullName,
        invitationCode
      } as ISignUpPost
    ).then((result: HttpResult<ILibraryUser>) => {
      Session.setUserInfo(result.data);
      return result.data;
    });
  }

  public static logIn(email: string, password: string): Promise<IUser> {
    return HttpClient.postFormUrlEncoded<ILibraryUser>(
      `${UserDAL.getRouteBase()}/login`,
      {
        email,
        password
      }
    ).then((result: HttpResult<ILibraryUser>) => {
      Session.setUserInfo(result.data);
      return result.data;
    });
  }

  public static signOut(): Promise<null> {
    return HttpClient.post<ISignOutResponse>(
      `${UserDAL.getRouteBase()}/signout`,
      {}
    ).then((result: HttpResult<ISignOutResponse>) => {
      Session.clearUserInfo();
      return null;
    });
  }

  public static setMostRecentLibrary(
    mostRecentLibraryId: string
  ): Promise<IUser> {
    return HttpClient.patch<IUser>(
      `${UserDAL.getRouteBase()}/${Session.getUserId()}`,
      {
        mostRecentLibraryId
      } as IUserPatch
    ).then((result: HttpResult<IUser>) => {
      Session.setMostRecentLibraryId(result.data.mostRecentLibraryId!);
      return result.data;
    });
  }

  public static sendPasswordReset(email: string): Promise<boolean> {
    return HttpClient.put<ISimpleResponse>(
      `${UserDAL.getRouteBase()}/sendpasswordreset`,
      {
        email
      } as IUserPatch
    ).then((result: HttpResult<ISimpleResponse>) => {
      return true;
    });
  }

  private static getRouteBase() {
    return `/api/users`;
  }
}
