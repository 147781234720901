import { IFile, IFileUpdate } from '@picstrata/client';
import { HttpClient, HttpResult, IFileBlob } from '@westlakelabs/http-client';
import Promise from 'bluebird';
import { Session } from '../common/Session';

export class FileDAL {
  public static getFile(fileId: string): Promise<IFile> {
    const libraryId = Session.getCurrentLibraryId();
    return HttpClient.get<IFile>(
      `/api/libraries/${libraryId}/files/${fileId}`
    ).then((result: HttpResult<IFile>) => {
      return result.data;
    });
  }

  public static updateFile(fileId: string, update: IFileUpdate) {
    const libraryId = Session.getCurrentLibraryId();
    return HttpClient.patch<IFile>(
      `/api/libraries/${libraryId}/files/${fileId}`,
      update
    ).then((result: HttpResult<IFile>) => {
      return result.data;
    });
  }

  public static deleteFile(fileId: string) {
    const libraryId = Session.getCurrentLibraryId();
    return HttpClient.delete<IFile>(
      `/api/libraries/${libraryId}/files/${fileId}`
    ).then((result: HttpResult<IFile>) => {
      return result.data;
    });
  }

  public static downloadFile(fileId: string) {
    const libraryId = Session.getCurrentLibraryId();
    return HttpClient.downloadFile(
      `/api/libraries/${libraryId}/files/${fileId}/contents?attachment=true`
    ).then((result: HttpResult<IFileBlob>) => {
      return result.data;
    });
  }

  public static copyFile(fileId: string, targetFolderId: string) {
    const libraryId = Session.getCurrentLibraryId();
    return HttpClient.post<IFile[]>(
      `/api/libraries/${libraryId}/folders/${targetFolderId}/files?source=${fileId}`,
      {}
    ).then((result: HttpResult<IFile[]>) => {
      return result.data[0];
    });
  }
}
