import { ILibraryUser } from '@picsilver/common';
import { ILibrary } from '@picstrata/client';

enum SessionKey {
  UserId = 'psv_user_id',
  UserEmail = 'psv_user_email',
  UserFullName = 'psv_user_full_name',
  UserInitials = 'psv_user_initials',
  MostRecentLibraryId = 'psv_most_recent_library_id',
  Libraries = 'psv_libraries',
  LibraryId = 'psv_library_id',
  LibraryName = 'psv_library_name',
  UserRole = 'psv_user_role'
}

export class Session {
  public static setUserInfo(user: ILibraryUser) {
    sessionStorage.setItem(SessionKey.UserId, user.userId);
    sessionStorage.setItem(SessionKey.UserEmail, user.email);
    sessionStorage.setItem(SessionKey.UserFullName, user.fullName);
    sessionStorage.setItem(SessionKey.UserInitials, user.initials);

    let currentLibrary: ILibrary | undefined;
    if (user.mostRecentLibraryId) {
      currentLibrary = user.libraries.find(
        l => l.libraryId === user.mostRecentLibraryId
      );
    }
    if (!currentLibrary) {
      currentLibrary = user.libraries[0];
    }

    sessionStorage.setItem(
      SessionKey.MostRecentLibraryId,
      currentLibrary.libraryId
    );
    sessionStorage.setItem(SessionKey.LibraryId, currentLibrary.libraryId);
    sessionStorage.setItem(SessionKey.LibraryName, currentLibrary.name);
    sessionStorage.setItem(SessionKey.UserRole, currentLibrary.userRole!);
    Session.setLibraries(user.libraries);
  }

  public static clearUserInfo() {
    sessionStorage.removeItem(SessionKey.UserRole);
    sessionStorage.removeItem(SessionKey.UserInitials);
    sessionStorage.removeItem(SessionKey.UserFullName);
    sessionStorage.removeItem(SessionKey.UserEmail);
    sessionStorage.removeItem(SessionKey.UserId);
  }

  public static getUserId() {
    const userId = sessionStorage.getItem(SessionKey.UserId);
    return userId === null ? undefined : userId;
  }

  public static getUserEmail() {
    const email = sessionStorage.getItem(SessionKey.UserEmail);
    return email === null ? undefined : email;
  }

  public static getUserInitials() {
    const initials = sessionStorage.getItem(SessionKey.UserInitials);
    return initials === null ? undefined : initials;
  }

  public static setCurrentLibrary(library: ILibrary) {
    sessionStorage.setItem(SessionKey.LibraryId, library.libraryId);
    sessionStorage.setItem(SessionKey.LibraryName, library.name);
    sessionStorage.setItem(SessionKey.UserRole, library.userRole!);
  }

  public static getCurrentLibraryId() {
    const libraryId = sessionStorage.getItem(SessionKey.LibraryId);
    return libraryId === null ? undefined : libraryId;
  }

  public static getCurrentLibraryName() {
    const libraryName = sessionStorage.getItem(SessionKey.LibraryName);
    return libraryName === null ? undefined : libraryName;
  }

  public static getLibraries() {
    const json = sessionStorage.getItem(SessionKey.Libraries);
    return json ? JSON.parse(json) : [];
  }

  public static addLibrary(library: ILibrary) {
    const libraries = Session.getLibraries();
    libraries.push(library);
    Session.setLibraries(libraries);
  }

  public static setMostRecentLibraryId(libraryId: string) {
    sessionStorage.setItem(SessionKey.MostRecentLibraryId, libraryId);
  }

  private static setLibraries(libraries: ILibrary[]) {
    sessionStorage.setItem(SessionKey.Libraries, JSON.stringify(libraries));
  }
}
