import * as React from 'react';
import {
  faChevronDown,
  faChevronRight,
  faFolder
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ListGroup, ListGroupItem } from 'reactstrap';
import { IFolder } from '@picstrata/client';
import { FolderDAL } from '../DAL/FolderDAL';
import './FolderTree.scss';
import classNames from 'classnames';

interface IFolderTreeProps {
  onSelect: (folder: IFolder) => void;
}

export const FolderTree = (props: IFolderTreeProps) => {
  const { onSelect } = props;
  const [parentFolderId, setParentFolderId] = React.useState<string>();
  const [selected, setSelected] = React.useState<IFolder>();

  const handleSelect = React.useCallback(
    (folder: IFolder) => {
      setSelected(folder);
      onSelect(folder);
    },
    [onSelect]
  );

  React.useEffect(() => {
    FolderDAL.getAllPicturesFolder().then(allPictures => {
      setParentFolderId(allPictures.folderId);
    });
  }, []);

  if (!parentFolderId) {
    return null;
  }

  return (
    <div className="psv-folder-tree">
      <FolderList
        parentFolderId={parentFolderId}
        selectedFolderId={selected?.folderId}
        selectFirst={true}
        onSelect={handleSelect}
      />
    </div>
  );
};

interface IFolderListProps {
  parentFolderId: string;
  selectedFolderId?: string;
  selectFirst?: boolean;
  onSelect: (folder: IFolder) => void;
}

interface ITreeViewItem {
  folder: IFolder;
  isExpanded: boolean;
}

const FolderList = (props: IFolderListProps) => {
  const { parentFolderId, selectedFolderId, selectFirst, onSelect } = props;
  const [treeViewItems, setTreeViewItems] = React.useState<ITreeViewItem[]>();

  React.useEffect(() => {
    FolderDAL.getFolders(parentFolderId).then(folders => {
      const treeViewItems = folders.map(f => ({
        folder: f,
        isExpanded: false
      }));
      setTreeViewItems(treeViewItems);
    });
  }, [parentFolderId]);

  React.useEffect(() => {
    if (
      selectFirst &&
      !selectedFolderId &&
      treeViewItems &&
      treeViewItems.length
    ) {
      onSelect(treeViewItems[0].folder);
    }
  }, [treeViewItems, selectedFolderId, selectFirst, onSelect]);

  const handleItemClick = React.useCallback(
    (e: React.MouseEvent<HTMLLIElement>) => {
      e.stopPropagation();
      const tvi = treeViewItems?.find(
        tvi => tvi.folder.folderId === e.currentTarget.id
      );
      if (tvi) {
        onSelect(tvi.folder);
      }
    },
    [onSelect, treeViewItems]
  );

  const handleToggleExpand = React.useCallback(
    (e: React.MouseEvent<SVGSVGElement>) => {
      e.stopPropagation();
      const folderId = e.currentTarget.parentElement!.id;
      setTreeViewItems(prevState =>
        prevState?.map(tvi =>
          tvi.folder.folderId === folderId
            ? { ...tvi, isExpanded: !tvi.isExpanded }
            : tvi
        )
      );
    },
    []
  );

  const handleChildSelect = React.useCallback(
    (folder: IFolder) => {
      // Propagate
      onSelect(folder);
    },
    [onSelect]
  );

  if (!treeViewItems) {
    return null;
  }

  return (
    <ListGroup type="inline" key={parentFolderId}>
      {treeViewItems.map(tvi => (
        <>
          <ListGroupItem
            key={tvi.folder.folderId}
            id={tvi.folder.folderId}
            className={classNames('psv-folder-item', {
              'psv-folder-item-selected':
                tvi.folder.folderId === selectedFolderId
            })}
            onClick={handleItemClick}
          >
            <FontAwesomeIcon
              icon={tvi.isExpanded ? faChevronDown : faChevronRight}
              className="psv-folder-expando psv-folder-chevron"
              onClick={handleToggleExpand}
            />
            <FontAwesomeIcon
              icon={faFolder}
              className="psv-folder-expando psv-folder-icon"
              onClick={handleToggleExpand}
            />
            {tvi.folder.name}
          </ListGroupItem>
          {tvi.isExpanded && (
            <FolderList
              parentFolderId={tvi.folder.folderId}
              selectedFolderId={selectedFolderId}
              onSelect={handleChildSelect}
            />
          )}
        </>
      ))}
    </ListGroup>
  );
};
