import { IAlbum } from '@picstrata/client';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { usePromises } from '../common/PromiseCollection';
import { Session } from '../common/Session';
import { AlbumDAL } from '../DAL/AlbumDAL';
import albumImg from '../images/album.svg';
import loadingImg from '../images/loading.gif';
import { AppNavbar } from './AppNavbar';
import { BreadcrumbBar, LibraryView } from './BreadcrumbBar';
import {
  AlbumGalleryContextMenu,
  useAlbumGalleryContextMenu
} from './ContextMenus';
import { ErrorModal } from './ErrorModal';
import { Gallery, IGalleryFolder } from './Gallery';
import './Library.scss';

enum AlbumsPageModal {
  Error
}

export const AlbumsPage = () => {
  const [albums, setAlbums] = React.useState<IAlbum[]>();
  const [showModal, setShowModal] = React.useState<AlbumsPageModal>();
  const [error, setError] = React.useState<string>();
  const promises = usePromises();
  const history = useHistory();

  const handleAlbumGalleryContextMenu = useAlbumGalleryContextMenu();

  /**
   * Fetches album information using the album ID in the URL.
   */
  React.useEffect(() => {
    promises.add(
      'getAlbums',
      AlbumDAL.getAlbums().then(a => {
        setAlbums(a);
      }),
      setAlbums
    );
  }, [promises]);

  const handleAlbumClick = React.useCallback(
    (folder: IGalleryFolder) => {
      const albumId = folder.folderId;
      history.push(
        `/libraries/${Session.getCurrentLibraryId()}/albums/${albumId}`
      );
    },
    [history]
  );

  const newAlbumDefaultName = React.useMemo(() => {
    let name = 'New Album';
    if (albums) {
      const isDupe = (nameToUse: string) =>
        albums!.find(a => a.name === nameToUse) !== undefined;
      let suffix = 2;
      let workingName = name;
      while (isDupe(workingName)) {
        workingName = name + suffix;
        suffix++;
      }
      name = workingName;
    }
    return name;
  }, [albums]);

  const handleAddAlbum = React.useCallback(() => {
    promises.add(
      'addAlbum',
      AlbumDAL.addAlbum(newAlbumDefaultName)
        .then(album => {
          setAlbums(prevState => {
            const updated = prevState?.slice() || [];
            updated.push(album);
            return updated;
          });
        })
        .catch(() => {
          setError('An error occurred while adding a new ablum.');
          setShowModal(AlbumsPageModal.Error);
        })
    );
  }, [promises, newAlbumDefaultName]);

  const handleErrorModalClosed = React.useCallback(() => {
    setError(undefined);
    setShowModal(undefined);
  }, []);

  return (
    <React.Fragment>
      <AppNavbar />

      <AlbumGalleryContextMenu onAddAlbum={handleAddAlbum} />

      <div className="psv-library-header">
        <div className="psv-library-name">
          {<BreadcrumbBar view={LibraryView.Album} />}
        </div>
      </div>

      {albums && (
        <Gallery
          className="psv-library-gallery"
          folders={albums.map(a => ({ folderId: a.albumId, name: a.name }))}
          onGalleryContextMenu={handleAlbumGalleryContextMenu}
          onOpenFolder={handleAlbumClick}
          folderImg={albumImg}
          loadingImg={loadingImg}
        />
      )}

      {showModal === AlbumsPageModal.Error && error && (
        <ErrorModal
          title="Error"
          message={error}
          onClose={handleErrorModalClosed}
        />
      )}
    </React.Fragment>
  );
};
