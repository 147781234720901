import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

interface IConfirmationModalProps {
  title: string;
  message: string;
  onClose: () => void;
}

/**
 * Generic error modal.
 */
export const ErrorModal = (props: IConfirmationModalProps) => {
  const { title, message, onClose } = props;

  const handleConfirmClick = React.useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <Modal isOpen={true} autoFocus={false} centered={true}>
      <ModalHeader toggle={handleConfirmClick}>{title}</ModalHeader>
      <ModalBody>{message}</ModalBody>
      <ModalFooter>
        <Button type="submit" color="primary" onClick={handleConfirmClick}>
          OK
        </Button>
      </ModalFooter>
    </Modal>
  );
};
