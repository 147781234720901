import { IFile } from '@picstrata/client';
import Promise from 'bluebird';
import * as React from 'react';
import { usePromises } from '../common/PromiseCollection';

const CheckProcessingIntervalMs = 1000;

/**
 * Hook that loads files from a folder or album and continues to poll the
 * server for updates to those files if any are flagged as processing.
 */
export const useFileLoader = (
  loadFiles: (parentId: string) => Promise<IFile[]>,
  parentId: string,
  files: IFile[] | undefined,
  setFiles: React.Dispatch<React.SetStateAction<IFile[] | undefined>>
) => {
  const [checkInterval, setCheckInterval] = React.useState<
    NodeJS.Timeout | undefined
  >();
  const promises = usePromises();

  /**
   * Checks to see if we still have files being processed.  Updates files previously
   * marked isProcessing === true with the latest state from the server.
   */
  const checkProcessing = React.useCallback(() => {
    // If we already have a request in flight do nothing this iteration.
    const promise = promises.get('checkProcessing') as
      | Promise<IFile[]>
      | undefined;
    if (promise && promise.isPending()) {
      return;
    }

    promises.add(
      'checkProcessing',
      loadFiles(parentId).then(result => {
        setFiles(previous => {
          const updatedFiles = previous!.map(f =>
            f.isProcessing ? result.find(nf => nf.fileId === f.fileId) || f : f
          );
          return updatedFiles;
        });
      })
    );
  }, [promises, loadFiles, parentId, setFiles]);

  /**
   * Issues a request to load files whenever parentId changes.
   */
  React.useEffect(() => {
    promises.add(
      'getFiles',
      loadFiles(parentId).then(result => {
        setFiles(result);
        return null;
      }),
      setFiles
    );
  }, [promises, loadFiles, parentId, setFiles]);

  /**
   * Ensures that the background checking function is running whenever new
   * files are loaded.
   */
  React.useEffect(() => {
    if (files) {
      const isProcessing = files.some(f => f.isProcessing);
      if (isProcessing && !checkInterval) {
        setCheckInterval(
          setInterval(checkProcessing, CheckProcessingIntervalMs)
        );
      } else if (!isProcessing && checkInterval) {
        clearInterval(checkInterval);
        setCheckInterval(undefined);
      }
    }
  }, [files, checkInterval, checkProcessing]);

  /**
   * Helper method for adding files to the set of loaded files.
   */
  const addFiles = React.useCallback(
    (newFiles: IFile[]) => {
      setFiles(previous => {
        const updatedFiles = previous!.slice();
        return updatedFiles.concat(newFiles);
      });
    },
    [setFiles]
  );

  /**
   * Helper method for removing a file from the set of loaded files.
   */
  const removeFile = React.useCallback(
    (file: IFile) => {
      setFiles(previous => {
        return previous!.filter(f => f.fileId !== file.fileId);
      });
    },
    [setFiles]
  );

  return { addFiles, removeFile };
};
