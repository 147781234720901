import { ILibrary } from '@picstrata/client';
import {
  ErrorMessage,
  Field,
  Form,
  Formik,
  FormikHelpers,
  FormikProps
} from 'formik';
import HttpStatusCode from 'http-status-codes';
import React from 'react';
import {
  Button,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import * as Yup from 'yup';
import { usePromises } from '../common/PromiseCollection';
import { LibraryDAL } from '../DAL/LibraryDAL';

interface ILibraryProps {
  name: string;
}

interface IAddLibraryModalProps {
  defaultValue: string;
  onClose: (library?: ILibrary) => void;
}

const validationSchema = Yup.object().shape({
  name: Yup.string().trim().required('Library name is required.')
});

export const AddLibraryModal = (props: IAddLibraryModalProps) => {
  const { defaultValue, onClose } = props;
  const promises = usePromises();
  const [error, setError] = React.useState<string | undefined>();

  const defaultValues = React.useMemo(() => {
    return {
      name: defaultValue
    } as ILibraryProps;
  }, [defaultValue]);

  const selectText = (input: HTMLInputElement) => {
    if (input) {
      input.select();
    }
  };

  const handleSubmit = React.useCallback(
    async (values: ILibraryProps, helpers: FormikHelpers<ILibraryProps>) => {
      helpers.setSubmitting(true);
      promises.add(
        'addLibrary',
        LibraryDAL.addLibrary(values.name)
          .then(library => {
            helpers.setSubmitting(false);
            onClose(library);
          })
          .catch(err => {
            if (err.status && err.status === HttpStatusCode.CONFLICT) {
              setError('A library with that name already exists.');
            } else {
              setError('An unexpected error occurred.');
            }
          })
      );
    },
    [promises, onClose]
  );

  const handleCancelClick = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      onClose();
    },
    [onClose]
  );

  return (
    <Formik
      initialValues={defaultValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      children={(formProps: FormikProps<ILibraryProps>) => {
        return (
          <Modal isOpen={true} autoFocus={false}>
            <ModalHeader toggle={handleCancelClick}>Add Library</ModalHeader>
            <ModalBody>
              <Form id="formProps">
                <Container>
                  <Row>
                    <Col className="form-group">
                      <label htmlFor="password">Library name:</label>
                      <Field
                        name="name"
                        placeholder="Enter library name"
                        className="form-control"
                        autoFocus={true}
                        innerRef={selectText}
                      />
                      <ErrorMessage name="name"></ErrorMessage>
                    </Col>
                  </Row>
                  {error && (
                    <Row>
                      <Col className="form-group">
                        <span className="danger-text">{error}</span>
                      </Col>
                    </Row>
                  )}
                </Container>
              </Form>
            </ModalBody>
            <ModalFooter>
              <Button
                color="secondary"
                disabled={formProps.isSubmitting}
                onClick={handleCancelClick}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                color="primary"
                disabled={formProps.isSubmitting}
                form="formProps"
              >
                Create
              </Button>
            </ModalFooter>
          </Modal>
        );
      }}
    />
  );
};
