import * as React from 'react';
import { ExportJobStatus } from '@picstrata/client';
import { IFileBlob } from '@westlakelabs/http-client';
import FileSaver from 'file-saver';
import { usePromises } from '../common/PromiseCollection';
import { ExportDAL } from '../DAL';
import loading from '../images/loading-transparent.gif';
import './ExportBanner.scss';

interface IExportBannerProps {
  exportJobIds: string[];
  onFinishJob: (jobId: string, error?: string) => void;
}

export const ExportBanner = (props: IExportBannerProps) => {
  const { exportJobIds, onFinishJob } = props;

  return (
    <div className="psv-export-banner">
      <img src={loading} alt="Loading" />
      Your files are being prepared for download. Please wait...
      {exportJobIds.map(jobId => (
        <ExportJobPoller key={jobId} jobId={jobId} onFinishJob={onFinishJob} />
      ))}
    </div>
  );
};

interface IExportJobPollerProps {
  jobId: string;
  onFinishJob: (jobId: string, error?: string) => void;
}

const ExportJobPoller = (props: IExportJobPollerProps) => {
  const { jobId, onFinishJob } = props;
  const promises = usePromises();

  const checkExports = React.useCallback(() => {
    promises.add(
      'getExportJob',
      ExportDAL.getExportJob(jobId).then(exportJob => {
        switch (exportJob.status) {
          case ExportJobStatus.Pending:
          case ExportJobStatus.Processing:
            window.setTimeout(checkExports, 2000);
            break;

          case ExportJobStatus.Success:
            promises.add(
              'download',
              ExportDAL.downloadExport(jobId)
                .then((fileBlob: IFileBlob) => {
                  fileBlob.blob.then((blob: Blob) => {
                    FileSaver.saveAs(blob, fileBlob.filename);
                    onFinishJob(jobId);
                    return fileBlob;
                  });
                })
                .catch(() => {
                  onFinishJob(
                    jobId,
                    'An error occurred downloading your exported files.'
                  );
                })
            );
            break;

          case ExportJobStatus.Failed:
            onFinishJob(
              jobId,
              'An error occurred preparing your files for export.'
            );
            break;

          default:
            onFinishJob(
              jobId,
              'An error occurred while processing your export. ' +
                exportJob.status
            );
            break;
        }
      })
    );
  }, [promises, jobId, onFinishJob]);

  React.useEffect(() => {
    checkExports();
  }, [jobId, checkExports]);

  return <div />;
};
