import {
  ErrorMessage,
  Field,
  Form,
  Formik,
  FormikHelpers,
  FormikProps
} from 'formik';
import HttpStatus from 'http-status-codes';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Col, Container, Navbar, NavbarBrand, Row } from 'reactstrap';
import * as Yup from 'yup';
import { usePromises } from '../common/PromiseCollection';
import { Session } from '../common/Session';
import * as routes from '../constants/routes';
import { FolderDAL } from '../DAL/FolderDAL';
import { UserDAL } from '../DAL/UserDAL';
import './LoginPage.scss';

interface ILogInValues {
  email: string;
  password: string;
}

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email address.')
    .required('Please enter your email address.'),
  password: Yup.string().required('Please provide a password.')
});

const defaultValues: ILogInValues = {
  email: '',
  password: ''
};

export const LogInPage = () => {
  const history = useHistory();
  const promises = usePromises();
  const [saveError, setSaveError] = React.useState<string | undefined>(
    undefined
  );

  const handleSubmit = React.useCallback(
    async (values: ILogInValues, helpers: FormikHelpers<ILogInValues>) => {
      helpers.setSubmitting(true);
      promises.add(
        'login',
        UserDAL.logIn(values.email, values.password)
          .then(success => {
            helpers.setSubmitting(false);
            return FolderDAL.getAllPicturesFolder().then(folder => {
              history.push(
                `/libraries/${Session.getCurrentLibraryId()}/folders/${
                  folder.folderId
                }`
              );
            });
          })
          .catch((err: any) => {
            setSaveError(
              err.status && err.status === HttpStatus.UNAUTHORIZED
                ? 'Invalid user name or password.'
                : 'An unexpected error occurred.'
            );
            helpers.setSubmitting(false);
          })
      );
      helpers.setSubmitting(false);
    },
    [promises, history]
  );

  return (
    <div>
      <Navbar dark color="dark">
        <NavbarBrand color="light">PICSILVER</NavbarBrand>
      </Navbar>
      <Formik
        initialValues={defaultValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        children={(props: FormikProps<ILogInValues>) => {
          return (
            <Form>
              <Container className="psv-inline-form">
                <h5>LOG IN</h5>
                <Row>
                  <Col className="form-group">
                    <label htmlFor="email">E-mail address: </label>
                    <Field
                      name="email"
                      placeholder="Enter your e-mail address"
                      className="form-control"
                      autoFocus={true}
                    />
                    <ErrorMessage
                      className="ps-error"
                      name="email"
                    ></ErrorMessage>
                  </Col>
                </Row>
                <Row>
                  <Col className="form-group">
                    <label htmlFor="password">Password:</label>
                    <Field
                      type="password"
                      name="password"
                      placeholder="Password to use when logging in"
                      className="form-control"
                    />
                    <ErrorMessage name="password"></ErrorMessage>
                    <a
                      className="psv-forgot-password-link"
                      href={routes.ForgotPassword}
                    >
                      Forgot your password?
                    </a>
                  </Col>
                </Row>
                {saveError && (
                  <Row>
                    <Col className="danger-text">{saveError}</Col>
                  </Row>
                )}

                <Row>
                  <Col>
                    <Button
                      className="psv-full-width-button"
                      color="primary"
                      type="submit"
                      disabled={
                        !props.dirty || !props.isValid || props.isSubmitting
                      }
                    >
                      Log In
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Form>
          );
        }}
      />
    </div>
  );
};
