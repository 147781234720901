import { IFile } from '@picstrata/client';
import React from 'react';
import { TriggerEvent } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.min.css';
import './Gallery.scss';

export interface IGalleryFolder {
  folderId: string;
  name: string;
}

interface IGalleryProps {
  folders?: IGalleryFolder[];
  files?: IFile[];
  getFileUrl?: (file: IFile) => string;
  onGalleryContextMenu?: (e: TriggerEvent) => void;
  onFolderContextMenu?: (e: TriggerEvent) => void;
  onFileContextMenu?: (e: TriggerEvent) => void;
  onOpenFolder?: (folder: IGalleryFolder) => void;
  onViewFile?: (file: IFile) => void;
  className?: string;
  folderImg?: string;
  loadingImg?: string;
}

// TODO: Only fetch file contents when image scrolled into view.

export const Gallery = (props: IGalleryProps) => {
  const {
    folders,
    files,
    getFileUrl,
    onGalleryContextMenu,
    onFolderContextMenu,
    onFileContextMenu,
    onOpenFolder,
    onViewFile
  } = props;

  const handleGalleryContextMenu = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (onGalleryContextMenu) {
        e.preventDefault();
        onGalleryContextMenu(e);
      }
    },
    [onGalleryContextMenu]
  );

  const handleFolderClick = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      const folderId = e.currentTarget.dataset.folderId;
      onOpenFolder!(folders!.find(f => f.folderId === folderId)!);
    },
    [onOpenFolder, folders]
  );

  const handleFolderContextMenu = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (onFolderContextMenu) {
        e.preventDefault();
        e.stopPropagation();
        onFolderContextMenu(e);
      }
    },
    [onFolderContextMenu]
  );

  const handleFileClick = React.useCallback(
    (e: React.MouseEvent<HTMLImageElement>) => {
      if (onViewFile) {
        onViewFile(files?.find(f => f.fileId === e.currentTarget.id)!);
      }
    },
    [onViewFile, files]
  );

  const handleFileContextMenu = React.useCallback(
    (e: React.MouseEvent<HTMLImageElement>) => {
      if (onFileContextMenu) {
        e.preventDefault();
        e.stopPropagation();
        onFileContextMenu(e);
      }
    },
    [onFileContextMenu]
  );

  return (
    <div
      className={`psv-gallery ${props.className}`}
      onContextMenu={handleGalleryContextMenu}
    >
      {folders && (
        <div className="psv-gallery-folders">
          {folders.map(f => (
            <div
              className="psv-gallery-folder-tile"
              id={f.folderId}
              key={f.folderId}
              style={{ backgroundImage: `url(${props.folderImg})` }}
              data-folder-id={f.folderId}
              onClick={handleFolderClick}
              onContextMenu={handleFolderContextMenu}
            >
              <div className="psv-gallery-folder-tile-name" title={f.name}>
                {f.name}
              </div>
            </div>
          ))}
        </div>
      )}

      {files &&
        files.map(f => (
          <div
            className="psv-gallery-file-tile psv-gallery-tile-medium"
            key={f.fileId}
            style={
              f.isProcessing
                ? { backgroundImage: `url(${props.loadingImg})` }
                : undefined
            }
          >
            <span className="psv-gallery-image-helper" />
            {!f.isProcessing && (
              <img
                id={f.fileId}
                src={getFileUrl!(f)}
                alt={f.name}
                title={f.name}
                className={
                  f.width >= f.height
                    ? 'psv-gallery-image-landscape'
                    : 'psv-gallery-image-portrait'
                }
                onClick={handleFileClick}
                onContextMenu={handleFileContextMenu}
              />
            )}
          </div>
        ))}
    </div>
  );
};
