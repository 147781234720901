import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

interface IConfirmationModalProps {
  title: string;
  message: string;
  confirmCommand: string;
  cancelCommand?: string;
  isDestructive?: boolean;
  onClose: (confirmed?: boolean) => void;
}

/**
 * Generic confirmation modal.
 */
export const ConfirmationModal = (props: IConfirmationModalProps) => {
  const {
    title,
    message,
    confirmCommand,
    cancelCommand,
    isDestructive,
    onClose
  } = props;

  const handleConfirmClick = React.useCallback(() => {
    onClose(true);
  }, [onClose]);

  const handleCancelClick = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      onClose();
    },
    [onClose]
  );

  return (
    <Modal isOpen={true} autoFocus={false} centered={true}>
      <ModalHeader toggle={handleCancelClick}>{title}</ModalHeader>
      <ModalBody>{message}</ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={handleCancelClick}>
          {cancelCommand ?? 'Cancel'}
        </Button>
        <Button
          type="submit"
          color={isDestructive ? 'danger' : 'primary'}
          onClick={handleConfirmClick}
        >
          {confirmCommand}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
