import {
  IBreadcrumb,
  IFile,
  IFolder,
  IFolderAdd,
  IFolderUpdate
} from '@picstrata/client';
import { HttpClient, HttpResult } from '@westlakelabs/http-client';
import Promise from 'bluebird';
import { Session } from '../common/Session';

export class FolderDAL {
  public static getAllPicturesFolder(): Promise<IFolder> {
    return HttpClient.get<IFolder[]>(
      `${FolderDAL.getRouteBase()}?parent=`
    ).then((result: HttpResult<IFolder[]>) => {
      return result.data[0];
    });
  }

  public static addFolder(
    parentFolderId: string,
    name: string
  ): Promise<IFolder> {
    return HttpClient.post<IFolder>(FolderDAL.getRouteBase(), {
      parentId: parentFolderId,
      name
    } as IFolderAdd).then((result: HttpResult<IFolder>) => {
      return result.data;
    });
  }

  public static getFolders(parentFolderId: string): Promise<IFolder[]> {
    return HttpClient.get<IFolder[]>(
      `${FolderDAL.getRouteBase()}?parent=${parentFolderId}`
    ).then((result: HttpResult<IFolder[]>) => {
      return result.data;
    });
  }

  public static getFolder(folderId: string): Promise<IFolder> {
    return HttpClient.get<IFolder>(
      `${FolderDAL.getRouteBase()}/${folderId}`
    ).then((result: HttpResult<IFolder>) => {
      return result.data;
    });
  }

  public static getFolderFiles(folderId: string): Promise<IFile[]> {
    return HttpClient.get<IFile[]>(
      `${FolderDAL.getRouteBase()}/${folderId}/files`
    ).then((result: HttpResult<IFile[]>) => {
      return result.data;
    });
  }

  public static getFolderBreadcrumbs(folderId: string): Promise<IBreadcrumb[]> {
    return HttpClient.get<IBreadcrumb[]>(
      `${FolderDAL.getRouteBase()}/${folderId}/breadcrumbs`
    ).then((result: HttpResult<IBreadcrumb[]>) => {
      return result.data;
    });
  }

  public static updateFolder(folderId: string, name: string): Promise<IFolder> {
    return HttpClient.patch<IFolder>(
      `${FolderDAL.getRouteBase()}/${folderId}`,
      {
        name
      } as IFolderUpdate
    ).then((result: HttpResult<IFolder>) => {
      return result.data;
    });
  }

  public static deleteFolder(folderId: string): Promise<IFolder> {
    return HttpClient.delete<IFolder>(
      `${FolderDAL.getRouteBase()}/${folderId}`
    ).then((result: HttpResult<IFolder>) => {
      return result.data;
    });
  }

  private static getRouteBase() {
    return `/api/libraries/${Session.getCurrentLibraryId()}/folders`;
  }
}
