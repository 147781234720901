import { IFolder } from '@picstrata/client';
import {
  ErrorMessage,
  Field,
  Form,
  Formik,
  FormikHelpers,
  FormikProps
} from 'formik';
import HttpStatusCode from 'http-status-codes';
import React from 'react';
import {
  Button,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import * as Yup from 'yup';
import { usePromises } from '../common/PromiseCollection';
import { FolderDAL } from '../DAL/FolderDAL';

interface IFolderProps {
  name: string;
}

interface IFolderModalProps {
  parentFolderId?: string;
  folderId?: string;
  defaultValue: string;
  onClose: (folder?: IFolder) => void;
}

const validationSchema = Yup.object().shape({
  name: Yup.string().trim().required('Folder name is required.')
});

export const FolderModal = (props: IFolderModalProps) => {
  const { defaultValue, parentFolderId, folderId, onClose } = props;
  const promises = usePromises();
  const [error, setError] = React.useState<string | undefined>();

  const defaultValues = React.useMemo(() => {
    return {
      name: defaultValue
    } as IFolderProps;
  }, [defaultValue]);

  const selectText = (input: HTMLInputElement) => {
    if (input) {
      input.select();
    }
  };

  const handleSubmit = React.useCallback(
    async (values: IFolderProps, helpers: FormikHelpers<IFolderProps>) => {
      helpers.setSubmitting(true);

      let promise;
      if (folderId) {
        promise = FolderDAL.updateFolder(folderId, values.name);
      } else {
        promise = FolderDAL.addFolder(parentFolderId!, values.name);
      }

      promises.add(
        'addUpdateFolder',
        promise
          .then(folder => {
            helpers.setSubmitting(false);
            onClose(folder);
          })
          .catch(err => {
            if (err.status && err.status === HttpStatusCode.CONFLICT) {
              setError('A folder with that name already exists.');
            } else {
              setError('An unexpected error occurred.');
            }
          })
      );
    },
    [promises, parentFolderId, folderId, onClose]
  );

  const handleCancelClick = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      onClose();
    },
    [onClose]
  );

  return (
    <Formik
      initialValues={defaultValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      children={(formProps: FormikProps<IFolderProps>) => {
        return (
          <Modal isOpen={true} autoFocus={false} centered={true}>
            <ModalHeader toggle={handleCancelClick}>
              {folderId ? 'Rename Folder' : 'Add Folder'}
            </ModalHeader>
            <ModalBody>
              <Form id="formProps">
                <Container>
                  <Row>
                    <Col className="form-group">
                      <label htmlFor="password">Folder name:</label>
                      <Field
                        name="name"
                        placeholder="Enter folder name"
                        className="form-control"
                        autoFocus={true}
                        innerRef={selectText}
                      />
                      <ErrorMessage name="name"></ErrorMessage>
                    </Col>
                  </Row>
                  {error && (
                    <Row>
                      <Col className="form-group">
                        <span className="danger-text">{error}</span>
                      </Col>
                    </Row>
                  )}
                </Container>
              </Form>
            </ModalBody>
            <ModalFooter>
              <Button
                color="secondary"
                disabled={formProps.isSubmitting}
                onClick={handleCancelClick}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                color="primary"
                disabled={formProps.isSubmitting}
                form="formProps"
              >
                Create
              </Button>
            </ModalFooter>
          </Modal>
        );
      }}
    />
  );
};
