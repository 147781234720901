import { faDownload, faStar, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IFile, IFolder } from '@picstrata/client';
import { IFileBlob } from '@westlakelabs/http-client';
import classnames from 'classnames';
import FileSaver from 'file-saver';
import * as React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Button } from 'reactstrap';
import { usePromises } from '../common/PromiseCollection';
import { IRecentFolder, Preferences, Session } from '../common';
import * as routes from '../constants/routes';
import { AlbumDAL } from '../DAL/AlbumDAL';
import { FileDAL } from '../DAL/FileDAL';
import { FolderDAL } from '../DAL/FolderDAL';
import { FileContextMenu, useFileContextMenu } from './ContextMenus';
import './FilePage.scss';

interface IPageParams {
  libraryId: string;
  folderId?: string;
  albumId?: string;
  fileId: string;
}

export const FilePage = () => {
  const [file, setFile] = React.useState<IFile | undefined>();
  const [files, setFiles] = React.useState<IFile[] | undefined>();
  const [fileIndex, setFileIndex] = React.useState<number | undefined>();
  const [parentName, setParentName] = React.useState<string>();
  const [parentUrl, setParentUrl] = React.useState<string>();
  const [recentFolders, setRecentFolders] = React.useState<IRecentFolder[]>([]);
  const match = useRouteMatch([routes.FolderFile, routes.AlbumFile]);
  const { libraryId, folderId, albumId, fileId } = match!.params as IPageParams;
  const promises = usePromises();
  const history = useHistory();

  const handleContextMenu = useFileContextMenu();

  React.useEffect(() => {
    promises.add(
      'getFileInfo',
      FileDAL.getFile(fileId).then(f => {
        setFile(f);
      }),
      setFile
    );

    // Is this file being viewed in the context of a folder?
    if (folderId) {
      promises.add(
        'getFolderInfo',
        FolderDAL.getFolder(folderId).then(f => {
          setParentName(f.name);
          setParentUrl(`/libraries/${libraryId}/folders/${folderId}`);
        }),
        setParentName
      );

      promises.add(
        'getFolderFiles',
        FolderDAL.getFolderFiles(folderId).then(folderFiles => {
          setFiles(folderFiles);
          setFileIndex(folderFiles.findIndex(f => f.fileId === fileId));
        }, setFiles)
      );
    }

    // Is this file being viewed in the context of an album?
    if (albumId) {
      promises.add(
        'getAlbumInfo',
        AlbumDAL.getAlbum(albumId).then(a => {
          setParentName(a.name);
          setParentUrl(`/libraries/${libraryId}/albums/${albumId}`);
        }),
        setParentName
      );

      promises.add(
        'getAlbumFiles',
        AlbumDAL.getAlbumFiles(albumId).then(albumFiles => {
          setFiles(albumFiles);
          setFileIndex(albumFiles.findIndex(f => f.fileId === fileId));
        }, setFiles)
      );
    }

    promises.add(
      'getRecentFolders',
      Preferences.getRecentFolders()
        .then(recent => {
          setRecentFolders(recent);
          return null;
        })
        .catch(() => {
          // Eat the error. Not the end of the world
        })
    );
  }, [libraryId, fileId, folderId, albumId, promises]);

  const isFavorite = React.useMemo(() => {
    return file && file.rating && file.rating > 0;
  }, [file]);

  const handleClickFavorite = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      FileDAL.updateFile(fileId, { rating: isFavorite ? 0 : 5 })
        .then(updatedFile => {
          const updatedFiles = files?.map(f =>
            f.fileId === fileId ? updatedFile : f
          );
          setFiles(updatedFiles);
          setFile(updatedFile);
          return null;
        })
        .catch(err => {
          alert('Error updating file.');
        });
    },
    [fileId, isFavorite, files]
  );

  const handleClickDownload = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      promises.add(
        'downloadFile',
        FileDAL.downloadFile(fileId).then((fileBlob: IFileBlob) => {
          fileBlob.blob.then((blob: Blob) => {
            FileSaver.saveAs(blob, fileBlob.filename);
          });
          return fileBlob;
        })
      );
    },
    [promises, fileId]
  );

  const handleDeleteClick = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      alert('Delete is not yet implemented.');
    },
    []
  );

  const handleClickPrevFile = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      const prevFileId = files![fileIndex! - 1].fileId;
      history.replace(`${parentUrl}/files/${prevFileId}`);
    },
    [files, fileIndex, parentUrl, history]
  );

  const handleClickNextFile = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      const nextFileId = files![fileIndex! + 1].fileId;
      history.replace(`${parentUrl}/files/${nextFileId}`);
    },
    [files, fileIndex, parentUrl, history]
  );

  const handleSelectFolder = React.useCallback((folder: IFolder) => {
    setRecentFolders(prevState => {
      let updated = prevState.slice() || [];
      updated.unshift({ folderId: folder.folderId, name: folder.name });
      return Preferences.setRecentFolders(updated);
    });
  }, []);

  const canDelete = React.useCallback((fileId: string) => {
    // TODO: Look for Devices folder
    return true;
  }, []);

  const handleDeleteFile = React.useCallback(
    (fileId: string) => {
      history.push(parentUrl!);
    },
    [history, parentUrl]
  );

  return (
    <div className="psv-file-page-container">
      <div className="psv-file-page-header">
        <div>
          <a href={parentUrl}>&lt; {parentName}</a>
        </div>

        <div style={{ flex: 1 }} />

        <div
          className="psv-file-toolbar-button"
          title="Download"
          onClick={handleClickDownload}
        >
          <FontAwesomeIcon icon={faDownload} />
        </div>

        <div
          className={classnames('psv-file-toolbar-button', {
            'psv-button-disabled': !isFavorite
          })}
          title="Mark as Favorite"
          onClick={handleClickFavorite}
        >
          <FontAwesomeIcon icon={faStar} />
        </div>

        <div
          className="psv-file-toolbar-button"
          title="Delete"
          onClick={handleDeleteClick}
        >
          <FontAwesomeIcon icon={faTrash} />
        </div>

        {fileIndex !== undefined && files && (
          <React.Fragment>
            <Button
              onClick={handleClickPrevFile}
              disabled={fileIndex === 0}
              color="link"
            >
              &lt; Previous
            </Button>
            <div>
              {fileIndex + 1} of {files?.length}
            </div>
            <Button
              onClick={handleClickNextFile}
              disabled={fileIndex >= files.length - 1}
              color="link"
            >
              Next &gt;
            </Button>
          </React.Fragment>
        )}
      </div>

      <FileContextMenu
        folderId={folderId}
        onSelectFolder={handleSelectFolder}
        canDeleteFile={canDelete}
        onDeleteFile={handleDeleteFile}
        recentFolders={recentFolders}
      />

      {file && (
        <div className="psv-file-container">
          {file.isVideo ? (
            <video
              id={file.fileId}
              src={`/api/libraries/${Session.getCurrentLibraryId()}/files/${
                file.fileId
              }/contents`}
              controls={true}
              onContextMenu={handleContextMenu}
            />
          ) : (
            <img
              id={file.fileId}
              src={`/api/libraries/${Session.getCurrentLibraryId()}/files/${
                file.fileId
              }/contents`}
              alt={file.name}
              onContextMenu={handleContextMenu}
            />
          )}
        </div>
      )}
    </div>
  );
};
