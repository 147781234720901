import * as React from 'react';
import {
  faCloudUploadAlt,
  faFolderPlus
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IFile, IFolder, ThumbnailSize } from '@picstrata/client';
import {
  DropzoneComponent,
  DropzoneComponentConfig,
  DropzoneComponentHandlers
} from 'react-dropzone-component';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { IRecentFolder, Preferences, Session, usePromises } from '../common';
import * as routes from '../constants/routes';
import { FolderDAL } from '../DAL/FolderDAL';
import { useFileLoader } from '../hooks/FileLoader';
import folderImg from '../images/folder.svg';
import loadingImg from '../images/loading.gif';
import { FolderModal } from './FolderModal';
import { AppNavbar } from './AppNavbar';
import { BreadcrumbBar, LibraryView } from './BreadcrumbBar';
import {
  GalleryContextMenu,
  useGalleryContextMenu,
  FolderContextMenu,
  useFolderContextMenu,
  FileContextMenu,
  useFileContextMenu
} from './ContextMenus';
import { ErrorModal } from './ErrorModal';
import { ExportBanner } from './ExportBanner';
import './FolderPage.scss';
import { Gallery, IGalleryFolder } from './Gallery';
import './Library.scss';

const FILE_UPLOAD_TIMEOUT_MINUTES = 5;
const MAX_FILE_UPLOAD_SIZE_MB = 500;
const UPLOAD_EXTENSIONS = [
  '.jpg',
  '.jpeg',
  '.png',
  '.gif',
  '.tif',
  '.tiff',
  '.mp4',
  '.mov',
  '.wmv',
  '.avi'
];

enum FolderPageModal {
  AddFolder = 1,
  Error = 2
}

export const FolderPage = () => {
  const history = useHistory();
  const match = useRouteMatch(routes.Folder);
  const libraryId = Session.getCurrentLibraryId();
  const folderId = (match!.params as any).folderId;
  const [files, setFiles] = React.useState<IFile[]>();
  const [folder, setFolder] = React.useState<IFolder>();
  const [subFolders, setSubFolders] = React.useState<IFolder[]>();
  const [recentFolders, setRecentFolders] = React.useState<IRecentFolder[]>([]);
  const [exportJobIds, setExportJobIds] = React.useState<string[]>([]);
  const [error, setError] = React.useState<string>();
  const [showModal, setShowModal] = React.useState<FolderPageModal>();
  const uploadFilesButton = React.useRef<HTMLDivElement>(null);
  const promises = usePromises();
  const fileLoader = useFileLoader(
    FolderDAL.getFolderFiles,
    folderId,
    files,
    setFiles
  );

  const handleGalleryContextMenu = useGalleryContextMenu();
  const handleFolderContextMenu = useFolderContextMenu();
  const handleFileContextMenu = useFileContextMenu();

  /**
   * Fetches folder information using the folder ID in the URL.
   */
  React.useEffect(() => {
    promises.add(
      'getFolder',
      FolderDAL.getFolder(folderId).then(result => {
        setFolder(result);
        return null;
      }),
      setFolder
    );

    promises.add(
      'getSubFolders',
      FolderDAL.getFolders(folderId).then(result => {
        setSubFolders(result);
        return null;
      }),
      setSubFolders
    );
  }, [folderId, promises]);

  React.useEffect(() => {
    Preferences.getRecentFolders()
      .then(recent => {
        setRecentFolders(recent);
      })
      .catch(() => {
        // Eat the error.  Not the end of the world.
      });
  }, []);

  const handleFileUploadSuccess = React.useCallback(
    (file: Dropzone.DropzoneFile, response: string | object) => {
      fileLoader.addFiles(response as IFile[]);
    },
    [fileLoader]
  );

  const handleFileUploadError = React.useCallback(
    (file: Dropzone.DropzoneFile, message: string | Error) => {
      alert(`Failure: ${message instanceof Error ? message.message : message}`);
    },
    []
  );

  const getFileUrl = React.useCallback((file: IFile) => {
    return `/api/libraries/${Session.getCurrentLibraryId()}/files/${
      file.fileId
    }/thumbnails/${ThumbnailSize.Medium}`;
  }, []);

  const handleOpenFolder = React.useCallback(
    (target: IGalleryFolder) => {
      history.push(
        `/libraries/${Session.getCurrentLibraryId()}/folders/${target.folderId}`
      );
    },
    [history]
  );

  const canDeleteFolder = React.useCallback((folderId: string) => {
    // TODO: Check to see if this is a Devices folder.
    return true;
  }, []);

  const handleDeleteFolder = React.useCallback((folderId: string) => {
    setSubFolders(previous => {
      return previous!.filter(f => f.folderId !== folderId);
    });
  }, []);

  const handleDownloadFolder = React.useCallback((jobId: string) => {
    setExportJobIds(prevState => {
      const updated = prevState.slice();
      updated.push(jobId);
      return updated;
    });
  }, []);

  const handleViewFile = React.useCallback(
    (file: IFile) => {
      history.push(
        `/libraries/${libraryId}/folders/${folderId}/files/${file.fileId}`
      );
    },
    [libraryId, folderId, history]
  );

  const canDeleteFile = React.useCallback((fileId: string) => {
    // TODO: Check to see if this is a Devices folder.
    return true;
  }, []);

  const handleDeleteFile = React.useCallback((fileId?: string) => {
    setFiles(prevState => prevState?.filter(f => f.fileId !== fileId));
  }, []);

  const handleSelectFolder = React.useCallback((folder: IFolder) => {
    setRecentFolders(prevState => {
      let updated = prevState.slice() || [];
      updated.unshift({ folderId: folder.folderId, name: folder.name });
      return Preferences.setRecentFolders(updated);
    });
  }, []);

  const componentConfig = React.useMemo(() => {
    return {
      iconFiletypes: ['.jpg', '.png', '.gif'],
      showFiletypeIcon: true,
      postUrl: `/api/libraries/${libraryId}/folders/${
        folder && folder.folderId
      }/files`
    } as DropzoneComponentConfig;
  }, [libraryId, folder]);

  const dropzoneOptions: Dropzone.DropzoneOptions = {
    paramName: 'files',
    clickable: `#upload-button`,
    dictDefaultMessage: '',
    acceptedFiles: UPLOAD_EXTENSIONS.join(','),
    timeout: FILE_UPLOAD_TIMEOUT_MINUTES * 60 * 1000,
    maxFilesize: MAX_FILE_UPLOAD_SIZE_MB
  };

  const eventHandlers: DropzoneComponentHandlers = {
    success: handleFileUploadSuccess,
    error: handleFileUploadError
  };

  const newFolderDefaultName = React.useMemo(() => {
    let name = 'New Folder';
    if (subFolders) {
      const isDupe = (nameToUse: string) =>
        subFolders!.find(f => f.name === nameToUse) !== undefined;
      let suffix = 2;
      let workingName = name;
      while (isDupe(workingName)) {
        workingName = name + suffix;
        suffix++;
      }
      name = workingName;
    }
    return name;
  }, [subFolders]);

  const handleAddFolder = React.useCallback(() => {
    setShowModal(FolderPageModal.AddFolder);
  }, []);

  const handleClickNewFolder = React.useCallback(
    (e: React.MouseEvent<SVGSVGElement>) => {
      e.preventDefault();
      handleAddFolder();
    },
    [handleAddFolder]
  );

  const handleUpdateFolder = React.useCallback((folder: IFolder) => {
    setSubFolders(prevState =>
      prevState?.map(f => (f.folderId === folder.folderId ? folder : f))
    );
    setRecentFolders(prevState =>
      prevState.map(r =>
        r.folderId === folder.folderId
          ? { folderId: folder.folderId, name: folder.name }
          : r
      )
    );
  }, []);

  const handleFinishExportJob = React.useCallback(
    (jobId: string, error?: string) => {
      setExportJobIds(prevState => {
        const updated = prevState.slice();
        return updated.filter(id => id !== jobId);
      });
      if (error) {
        setError(error);
        setShowModal(FolderPageModal.Error);
      }
    },
    []
  );

  const handleCloseFolderModal = React.useCallback((newFolder?: IFolder) => {
    setShowModal(undefined);
    if (newFolder) {
      setSubFolders(previous => {
        const updatedSubFolders = previous!.slice();
        updatedSubFolders.push(newFolder);
        return updatedSubFolders;
      });
    }
  }, []);

  const handleCloseErrorModal = React.useCallback(() => {
    setShowModal(undefined);
  }, []);

  return (
    <React.Fragment>
      <AppNavbar />

      <GalleryContextMenu
        folderId={folderId}
        uploadFilesButton={uploadFilesButton.current}
        onSelectAddFolder={handleAddFolder}
      />

      <FolderContextMenu
        canDeleteFolder={canDeleteFolder}
        onUpdateFolder={handleUpdateFolder}
        onDeleteFolder={handleDeleteFolder}
        onDownloadFolder={handleDownloadFolder}
      />

      <FileContextMenu
        folderId={folderId}
        canDeleteFile={canDeleteFile}
        onDeleteFile={handleDeleteFile}
        onSelectFolder={handleSelectFolder}
        recentFolders={recentFolders}
      />

      <div className="psv-library-header">
        <div className="psv-library-name">
          {folder && (
            <BreadcrumbBar view={LibraryView.Folder} folder={folder} />
          )}
        </div>
        <div className="psv-library-toolbar">
          <div className="psv-library-toolbar-button" title="Add folder">
            <FontAwesomeIcon
              icon={faFolderPlus}
              onClick={handleClickNewFolder}
            />
          </div>
          <div
            id="upload-button"
            className="psv-library-toolbar-button"
            title="Upload files"
            ref={uploadFilesButton}
          >
            <FontAwesomeIcon icon={faCloudUploadAlt} />
          </div>
        </div>
      </div>

      {exportJobIds && exportJobIds.length > 0 && (
        <ExportBanner
          exportJobIds={exportJobIds}
          onFinishJob={handleFinishExportJob}
        />
      )}

      {files && subFolders && (
        <Gallery
          className="psv-library-gallery"
          folders={subFolders}
          files={files}
          getFileUrl={getFileUrl}
          onGalleryContextMenu={handleGalleryContextMenu}
          onFolderContextMenu={handleFolderContextMenu}
          onFileContextMenu={handleFileContextMenu}
          onOpenFolder={handleOpenFolder}
          onViewFile={handleViewFile}
          folderImg={folderImg}
          loadingImg={loadingImg}
        />
      )}

      <DropzoneComponent
        className="psv-library-dropzone"
        config={componentConfig}
        djsConfig={dropzoneOptions}
        eventHandlers={eventHandlers}
      ></DropzoneComponent>

      {showModal === FolderPageModal.AddFolder && folder && (
        <FolderModal
          onClose={handleCloseFolderModal}
          parentFolderId={folder.folderId}
          defaultValue={newFolderDefaultName}
        />
      )}

      {showModal === FolderPageModal.Error && error && (
        <ErrorModal
          title="Error"
          message={error}
          onClose={handleCloseErrorModal}
        />
      )}
    </React.Fragment>
  );
};
