import Promise from 'bluebird';
import * as React from 'react';

/**
 * A collection of promises.
 */
export class PromiseCollection {
  private promises: Map<string, Promise<any>>;

  constructor() {
    this.promises = new Map<string, Promise<any>>();
  }

  /**
   * Adds a promise to the collection. If a promise with that ID was already
   * added to the collection and has not finished, an error is thrown.
   *
   * @param id ID of the promise.
   * @param promise The promise.
   * @param onError A function to call when the promise catches an error. This
   * can be any React setState method (i.e. return value of
   * React.useState(...)). The reason for this is that React Error Boundaries do
   * not catch asynchronous errors that occur. See:
   * https://github.com/facebook/react/issues/14981#issuecomment-468460187.
   */
  public add = <TPromise extends any, TState>(
    id: string,
    promise: Promise<TPromise>,
    onError?: React.Dispatch<React.SetStateAction<TState>>
  ) => {
    const oldPromise = this.promises.get(id);
    if (oldPromise && oldPromise.isPending && oldPromise.isPending()) {
      throw new Error(
        'Assertion failed: Attempted to replace a pending promise without canceling it'
      );
    }

    if (onError) {
      this.promises.set(
        id,
        promise.catch(e => {
          onError(() => {
            throw e;
          });
        })
      );
    } else {
      this.promises.set(id, promise);
    }

    return promise;
  };

  /**
   * Gets a promise from the collection by ID.
   */
  public get = <TPromise extends any>(id: string): TPromise | undefined => {
    const promise = this.promises.get(id);
    if (promise === undefined) {
      return undefined;
    }

    return (promise as unknown) as TPromise;
  };

  /**
   * Deletes a promise from the collection by ID, cancelling it if necessary
   */
  public delete = (id: string): boolean => {
    const oldPromise = this.promises.get(id);
    if (oldPromise && oldPromise.isPending && oldPromise.isPending()) {
      oldPromise.cancel();
    }
    return this.promises.delete(id);
  };

  /**
   * Cancels and clears all promises from the dictionary
   */
  public clear = () => {
    for (const promise of this.promises.values()) {
      if (promise.isPending()) {
        promise.cancel();
      }
    }
    this.promises.clear();
  };

  /**
   * Cancels all promises in the collection.
   */
  public cancelAll = () => {
    for (const promise of this.promises.values()) {
      if (promise.isPending()) {
        promise.cancel();
      }
    }
  };
}

/**
 * A hook for using a collection of promises.
 */
export const usePromises = () => {
  const promiseCollection = React.useRef(new PromiseCollection());

  React.useEffect(() => {
    const currentCollection = promiseCollection.current;
    return function cleanup() {
      if (currentCollection) {
        currentCollection.clear();
      }
    };
  }, []);

  return promiseCollection.current;
};

export const useFatalError = (err: Error) => {
  React.useState(() => {
    throw err;
  });
};
